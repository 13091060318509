import * as Types         from '../mutationTypes';
import ObjectManipulation from '@/utils/object';
import HestiaApi          from '@/api';

// initial state
const state = {
  requests       : {
    results    : [],
    pagination : {
      perPage : 10,
      page    : 1,
      total   : 0
    }
  },
  request_source : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  },

  request_received : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  },
  request_inbounds : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  },
  similar_requests : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  }
};

// getters
const getters = {
  requests         : state => state.requests,
  request_source   : state => state.request_source,
  request_received : state => state.request_received,
  request_inbounds : state => state.request_inbounds,
  similar_requests : state => state.similar_requests
};

// actions
const actions = {
  async getRequests({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Lead.request.list(tenantId, { searchParams : query }).json();
    commit(Types.RECEIVE_REQUESTS, response);
  },
  async getSimilarRequests({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Lead.request.similar(tenantId, { searchParams : query }).json();
    commit(Types.RECEIVE_SIMILAR_REQUESTS, response);
  },
  async addRequest({ commit }, { tenantId, request }) {

    const response = await HestiaApi.Lead.request.create(tenantId, request).json();

    commit(Types.ADD_REQUEST, response);

    return response;
  },
  async editRequest({ commit }, { tenantId, id, request }) {

    const response = await HestiaApi.Lead.request.update(tenantId, id, request).json();

    commit(Types.EDIT_REQUEST, response);
  },
  // SOURCE
  async addRequestSource({ commit }, { tenantId, source }) {

    const response = await HestiaApi.Lead.source.create(tenantId, source).json();
    commit(Types.ADD_REQUEST_SOURCE, response);
    return response;
  },
  async getRequestSources({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Lead.source.list(tenantId, { searchParams : query }).json();
    commit(Types.RECEIVE_REQUEST_SOURCES, response);
  },
  async removeRequestSource({ commit }, { tenantId, id }) {

    await HestiaApi.Lead.source.delete(tenantId, id);
    commit(Types.REMOVE_REQUEST_SOURCE, id);
  },
  async editRequestSource({ commit }, { tenantId, sourceId, source }) {

    const response = await HestiaApi.Lead.source.update(tenantId, sourceId, source).json();
    commit(Types.EDIT_REQUEST_SOURCE, response);
  },
  // INBOUND
  async addRequestInbound({ commit }, { tenantId, inbound }) {

    const response = await HestiaApi.Lead.inbound.create(tenantId, inbound).json();
    commit(Types.ADD_REQUEST_INBOUND, response);
    return response;
  },
  async getLeadInbounds({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Lead.inbound.list(tenantId, { searchParams : query }).json();
    commit(Types.RECEIVE_REQUEST_INBOUNDS, response);
  },
  async removeRequestInbound({ commit }, { tenantId, id }) {

    await HestiaApi.Lead.inbound.delete(tenantId, id);
    commit(Types.REMOVE_REQUEST_INBOUND, id);
  },
  async editRequestInbound({ commit }, { tenantId, previousInboundName, inbound }) {

    const response = await HestiaApi.Lead.inbound.update(tenantId, previousInboundName, inbound).json();
    commit(Types.EDIT_REQUEST_INBOUND, response);
  },
  // RECEIVED
  async getRequestReceived({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Lead.received.list(tenantId, { searchParams : query }).json();
    commit(Types.RECEIVE_REQUEST_RECEIVED, response);
  }
};

// mutations
const mutations = {
  [Types.RECEIVE_REQUESTS](state, requests) {

    state.requests = requests;
  },
  [Types.RECEIVE_SIMILAR_REQUESTS](state, requests) {

    state.similar_requests = requests;
  },
  [Types.ADD_REQUEST](state, request) {

    state.requests.results.unshift(request);
  },
  [Types.EDIT_REQUEST](state, request) {

    const index = state.requests.results.findIndex((r) => r.id === request.id);
    state.requests.results.splice(index, 1, request);

    state.requests = ObjectManipulation.clone(state.requests); // else deep objects are not updated, if it's not a deep object use splice instead ;)
  },
  [Types.RECEIVE_REQUEST_SOURCES](state, sources) {

    state.request_source = sources;
  },
  [Types.ADD_REQUEST_SOURCE](state, source) {

    state.request_source.results.unshift(source);

    if (state.request_source.results.length > 10) {
      state.request_source.results.pop();
    }
  },
  [Types.REMOVE_REQUEST_SOURCE](state, id) {

    const index = state.request_source.results.findIndex((source) => source.id === id);
    state.request_source.results.splice(index, 1);
  },
  [Types.EDIT_REQUEST_SOURCE](state, data) {

    const index = state.request_source.results.findIndex((source) => source.id === data.id);
    state.request_source.results.splice(index, 1, data);
  },
  [Types.RECEIVE_REQUEST_SOURCES](state, sources) {

    state.request_source = sources;
  },
  // INBOUND
  [Types.RECEIVE_REQUEST_INBOUNDS](state, inbounds) {

    state.request_inbounds = inbounds;
  },
  [Types.ADD_REQUEST_INBOUND](state, inbound) {

    state.request_inbounds.results.push(inbound);
  },
  [Types.REMOVE_REQUEST_INBOUND](state, id) {

    const index = state.request_inbounds.results.findIndex((inbound) => inbound.id === id);
    state.request_inbounds.results.splice(index, 1);
  },
  [Types.EDIT_REQUEST_INBOUND](state, data) {

    const index = state.request_inbounds.results.findIndex((inbound) => inbound.id === data.id);
    state.request_inbounds.results.splice(index, 1, data);
  },
  // RECEIVED
  [Types.RECEIVE_REQUEST_RECEIVED](state, received) {

    state.request_received = received;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
