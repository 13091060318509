import * as Types from '../mutationTypes';
import HestiaApi  from '@/api';

// initial state
const state = {
  apporteurs : {
    results    : [],
    pagination : {
      perPage : 10,
      page    : 1,
      total   : 0
    }
  }
};

// getters
const getters = {
  apporteurs : state => state.apporteurs
};

// actions
const actions = {
  async getApporteurs({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Apporteur.apporteur.list(tenantId, { searchParams : query }).json();
    commit(Types.RECEIVE_APPORTEURS, response);
  }
};

// mutations
const mutations = {
  [Types.RECEIVE_APPORTEURS](state, apporteurs) {

    state.apporteurs = apporteurs;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
