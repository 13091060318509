import 'nprogress/nprogress.css';

import Config from '@/config';
import RBAC   from '@pixul/hestia-rbac';
import Router from 'vue-router';
import Vue    from 'vue';
import i18n   from '@/lang'; // Internationalization
import store  from '@/store/index.js';
import qs     from 'qs';

import _import from './import';

Vue.use(Router);

const allowEmptyParent = (routes) => {

  if (!Array.isArray(routes)) {
    return routes;
  }

  return routes.map((route) => {

    if (!route.component) {
      route.component = {
        render(c) {

          return c('router-view');
        }
      };
    }

    if (route.children) {

      route.children = allowEmptyParent(route.children);
    }

    return route;
  });
};

export const fixedRouterMap = [

  // *********************
  // *****  TENANT  ******
  {
    path      : '/tenant/:tenant/login/google',
    component : _import('login/index'),
    hidden    : true,
    name      : 'login-google',
    meta      : { layout : 'simple' }
  }, {
    path      : '/tenant/:tenant/login/azure',
    component : _import('login/index'),
    hidden    : true,
    name      : 'login-azure',
    meta      : { layout : 'simple' }
  }, {
    path      : '/tenant/:tenant/login',
    component : _import('login/index'),
    hidden    : true,
    name      : 'login',
    meta      : { icon : 'user', layout : 'simple' },
    props     : true
  }, {
    path      : '/login',
    component : _import('error/missingTenant'),
    hidden    : true,
    name      : 'login-without-tenant',
    meta      : { icon : 'user', layout : 'simple' },
    props     : true
  }, {
    path      : '/tenant/login',
    component : _import('error/missingTenant'),
    hidden    : true,
    meta      : { icon : 'user', layout : 'simple' },
    props     : true
  }, {
    path      : '/tenant/new',
    component : _import('tenant/new'),
    hidden    : true,
    name      : 'tenant-new',
    meta      : { icon : 'user', title : 'New Tenant', layout : 'simple' }
  }, {
    name      : 'docusign-embeded',
    path      : '/tenant/:tenant/embeded/signing',
    component : _import('docusign/sign'),
    hidden    : true,
    meta      : { layout : 'simple' }
  }, {
    path      : '/tenant/:tenant/',
    component : _import('dashboard/index'),
    name      : 'dashboard',
    hidden    : true,
    meta      : { title : 'sidebar.dashboard', icon : 'tachometer-alt', layout : 'default' }
  }, {
    path     : '/',
    name     : 'home',
    redirect : to => {

      return {
        name   : 'dashboard',
        params : to.params
      };
    },
    hidden   : true,
    meta     : { title : 'sidebar.dashboard', icon : 'tachometer-alt', layout : 'default' }
  }, {
    path      : '/tenant/:tenant/logout',
    hidden    : true,
    component : _import('logout/index'),
    name      : 'logout',
    meta      : { title : 'logout', layout : 'simple' }
  },
  // *********************
  // ****** PROPERTY *****
  // *********************
  {
    name     : 'properties',
    path     : '/tenant/:tenant/properties',
    redirect : to => {

      return {
        name   : 'properties-list',
        params : to.params
      };
    },
    meta     : {
      title : 'sidebar.properties', icon : 'bien-upload', iconSize : 96, hideChildrens : true, layout : 'default'
    },
    children : [
      {
        name      : 'properties-list',
        path      : '/tenant/:tenant/properties/list',
        component : _import('properties/index'),
        props     : true,
        meta      : { title : 'sidebar.propertiesList', layout : 'default' }
      }, {
        name      : 'partner-properties',
        path      : '/tenant/:tenant/properties/partners-properties-list',
        component : _import('partner/properties/index'),
        hidden    : true,
        meta      : { title : 'sidebar.propertiesPartners', layout : 'default' }
      }, {
        name      : 'properties-create',
        path      : '/tenant/:tenant/properties/add',
        component : _import('properties/add'),
        hidden    : true,
        meta      : { title : 'main.action.create_property', layout : 'default' },
        props     : true
      }, {
        name      : 'properties-view',
        path      : '/tenant/:tenant/properties/:id(PRO.*)',
        redirect  : 'details',
        component : _import('properties/view'),
        meta      : { title : 'sidebar.view', layout : 'default' },
        hidden    : true,
        children  : [
          {
            name      : 'properties-view-details',
            path      : 'details',
            component : _import('properties/components/PropertyTabDetails'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'properties-view-description',
            path      : 'description',
            component : _import('properties/components/PropertyTabDescription'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'properties-view-features',
            path      : 'features',
            component : _import('properties/components/PropertyTabFeatures'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'properties-view-documents',
            path      : 'documents',
            component : _import('properties/components/PropertyTabDocuments'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'properties-view-estimation',
            path      : 'estimation',
            component : _import('properties/components/PropertyTabEstimation'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'properties-view-dpe',
            path      : 'dpe',
            component : _import('properties/components/PropertyTabDpe'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'properties-view-images',
            path      : 'images',
            component : _import('properties/components/PropertyTabImages'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'properties-view-issues',
            path      : 'issues',
            component : _import('properties/components/PropertyTabIssues'),
            meta      : { title : 'property.tab.issues', layout : 'default', noScrollTab : true }
          }, {
            name      : 'properties-view-clients',
            path      : 'clients',
            component : _import('properties/components/PropertyTabClients'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'properties-view-mandates',
            path      : 'mandates',
            component : _import('properties/components/PropertyTabMandates'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'properties-view-service',
            path      : 'service',
            component : _import('properties/components/PropertyTabService'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'properties-view-medias',
            path      : 'medias',
            component : _import('properties/components/PropertyTabPhotosMedias'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'properties-view-appointments',
            path      : 'appointments',
            component : _import('properties/components/PropertyTabAppointment'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'properties-view-offers',
            path      : 'offers',
            component : _import('properties/components/PropertyTabOffer'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'properties-view-feeds',
            path      : 'feeds',
            component : _import('properties/components/PropertyTabFeedsPublications'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'properties-view-matches',
            path      : 'matches',
            component : _import('properties/components/PropertyTabMatches'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'properties-view-requests',
            path      : 'requests',
            component : _import('properties/components/PropertyTabRequests'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'properties-view-publications',
            path      : 'publications',
            component : _import('properties/components/PropertyTabPublications'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'properties-view-overview',
            path      : 'overview',
            component : _import('properties/components/PropertyTabOverview'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }
        ]
      },
      {
        name     : 'properties-offers',
        path     : '/tenant/:tenant/properties/offers',
        redirect : to => {

          return {
            name   : 'properties-offers-list',
            params : to.params
          };
        },
        meta     : { title : 'sidebar.offers', layout : 'default', hideChildrens : true },
        children : [
          {
            name      : 'properties-offers-list',
            path      : 'list',
            component : _import('offers/index'),
            meta      : { title : 'sidebar.offers', icon : 'list', layout : 'default' },
            props     : true
          }
        ]
      }
    ]
  },
  // *********************
  // ******  CLIENT ******
  // *********************
  {
    name     : 'clients',
    path     : '/tenant/:tenant/clients',
    redirect : to => {

      return {
        name   : 'clients-individuals-list',
        params : to.params
      };
    },
    meta     : { title : 'sidebar.clients', icon : 'client-brown', iconSize : 96, layout : 'default' },
    children : [
      {
        name     : 'clients-individuals',
        path     : '/tenant/:tenant/clients/individuals',
        redirect : to => {

          return {
            name   : 'clients-individuals-list',
            params : to.params
          };
        },
        meta     : { title : 'sidebar.individuals', layout : 'default', hideChildrens : true },
        children : [
          {
            name      : 'clients-individuals-list',
            path      : '/tenant/:tenant/clients/individuals/list',
            props     : true,
            component : _import('clients/index'),
            meta      : { title : 'sidebar.individuals', icon : 'list', layout : 'default' }
          }, {
            name      : 'clients-individuals-create',
            path      : '/tenant/:tenant/clients/individuals/add',
            component : _import('clients/add'),
            hidden    : true,
            meta      : { title : 'main.action.create_client', layout : 'default' }
          }, {
            name      : 'clients-individuals-view',
            path      : '/tenant/:tenant/clients/individuals/:id',
            component : _import('clients/view'),
            redirect  : 'details',
            hidden    : true,
            meta      : { title : 'sidebar.view', layout : 'default' },
            children  : [
              {
                name      : 'clients-individuals-view-details',
                path      : 'details',
                component : _import('clients/components/ClientTabDetails'),
                meta      : { title : false, layout : 'default', noScrollTab : true }
              }, {
                name      : 'clients-individuals-view-emails',
                path      : 'emails',
                component : _import('clients/components/ClientTabEmails'),
                meta      : { title : false, layout : 'default', noScrollTab : true }
              }, {
                name      : 'clients-individuals-view-phones',
                path      : 'phones',
                component : _import('clients/components/ClientTabPhones'),
                meta      : { title : false, layout : 'default', noScrollTab : true }
              }, {
                name      : 'clients-individuals-view-contact',
                path      : 'contact',
                component : _import('clients/components/ClientTabContact'),
                meta      : { title : false, layout : 'default' }
              }, {
                name      : 'clients-individuals-view-documents',
                path      : 'documents',
                component : _import('clients/components/ClientTabDocuments'),
                meta      : { title : false, layout : 'default', noScrollTab : true }
              }, {
                name      : 'clients-individuals-view-matches',
                path      : 'matchs-list',
                component : _import('clients/components/ClientTabMatchs'),
                meta      : { title : false, layout : 'default', noScrollTab : true }
              }, {
                name      : 'clients-individuals-view-discussions',
                path      : 'discussions',
                component : _import('clients/components/ClientTabDiscussions'),
                meta      : { title : false, layout : 'default', noScrollTab : true }
              }, {
                name      : 'clients-individuals-view-properties',
                path      : 'properties',
                component : _import('clients/components/ClientTabProperties'),
                meta      : { title : false, layout : 'default', noScrollTab : true }
              }, {
                name      : 'clients-individuals-view-mandates',
                path      : 'mandates',
                component : _import('clients/components/ClientTabMandates'),
                meta      : { title : false, layout : 'default', noScrollTab : true }
              }, {
                name      : 'clients-individuals-view-companies',
                path      : 'companies',
                component : _import('clients/components/ClientTabCompanies'),
                meta      : { title : false, layout : 'default', noScrollTab : true }
              }, {
                name      : 'clients-individuals-view-relations',
                path      : 'relations',
                component : _import('clients/components/ClientTabRelations'),
                meta      : { title : false, layout : 'default', noScrollTab : true }
              }, {
                name      : 'clients-individuals-view-appointments',
                path      : 'appointments',
                component : _import('clients/components/ClientTabAppointments'),
                meta      : { title : false, layout : 'default', noScrollTab : true }
              }, {
                name      : 'clients-individuals-view-issues',
                path      : 'issues',
                component : _import('clients/components/ClientTabIssues'),
                meta      : { title : false, layout : 'default', noScrollTab : true }
              }, {
                name      : 'clients-individuals-view-offers',
                path      : 'offers',
                component : _import('clients/components/ClientTabOffers'),
                meta      : { title : false, layout : 'default', noScrollTab : true }
              }
            ]
          }
        ]
      }, {
        name     : 'clients-companies',
        path     : '/tenant/:tenant/clients/companies',
        redirect : to => {

          return {
            name   : 'clients-companies-list',
            params : to.params
          };
        },
        meta     : { title : 'sidebar.companies', layout : 'default', hideChildrens : true },
        children : [
          {
            name      : 'clients-companies-list',
            path      : '/tenant/:tenant/clients/companies/list',
            component : _import('companies/index'),
            props     : true,
            meta      : { title : 'sidebar.companies', icon : 'list', layout : 'default' }
          }, {
            name      : 'clients-companies-create',
            path      : '/tenant/:tenant/clients/companies/add',
            component : _import('companies/add'),
            hidden    : true,
            meta      : { title : 'main.action.create_company', layout : 'default' }
          }, {
            name      : 'clients-companies-view',
            path      : '/tenant/:tenant/clients/companies/:id',
            component : _import('companies/view'),
            redirect  : 'details',
            hidden    : true,
            meta      : { title : 'sidebar.view', layout : 'default' },
            children  : [
              {
                name      : 'clients-companies-view-details',
                path      : 'details',
                component : _import('companies/components/CompanyTabDetails'),
                meta      : { title : false, layout : 'default', noScrollTab : true }
              }, {
                name      : 'clients-companies-view-clients',
                path      : 'clients',
                component : _import('companies/components/CompanyTabClients'),
                meta      : { title : false, layout : 'default', noScrollTab : true }
              }, {
                name      : 'clients-companies-view-properties',
                path      : 'properties',
                component : _import('companies/components/CompanyTabProperties'),
                meta      : { title : false, layout : 'default', noScrollTab : true }
              }, {
                name      : 'clients-companies-view-mandates',
                path      : 'mandates',
                component : _import('companies/components/CompanyTabMandates'),
                meta      : { title : false, layout : 'default', noScrollTab : true }
              }, {
                name      : 'clients-companies-view-documents',
                path      : 'documents',
                component : _import('companies/components/CompanyTabDocuments'),
                meta      : { title : false, layout : 'default', noScrollTab : true }
              }, {
                name      : 'clients-companies-view-issues',
                path      : 'issues',
                component : _import('companies/components/CompanyTabIssues'),
                meta      : { title : false, layout : 'default', noScrollTab : true }
              }
            ]
          }
        ]
      },
      // *********************
      // ******  REQUESTS  ******
      // *********************
      {
        name     : 'clients-requests',
        path     : '/tenant/:tenant/clients/requests',
        redirect : to => {

          return {
            name   : 'clients-requests-list',
            params : to.params
          };
        },
        meta     : { title : 'sidebar.requests', layout : 'default', hideChildrens : true },
        children : [
          {
            name      : 'clients-requests-list',
            path      : '/tenant/:tenant/clients/requests/list',
            component : _import('requests/index'),
            meta      : { title : 'sidebar.requests', icon : 'list', layout : 'default' },
            props     : true
          }, {
            name      : 'clients-requests-answer',
            path      : '/tenant/:tenant/clients/requests/answer',
            component : _import('requests/answer'),
            hidden    : true,
            meta      : { title : 'main.action.answer', layout : 'default' },
            props     : true
          }
        ]
      }, {
        name     : 'clients-appointments',
        path     : '/tenant/:tenant/clients/appointments',
        redirect : to => {

          return {
            name   : 'clients-appointments-list',
            params : to.params
          };
        },
        meta     : { title : 'sidebar.appointments', layout : 'default', hideChildrens : true },
        children : [
          {
            name      : 'clients-appointments-list',
            path      : '/tenant/:tenant/clients/appointments/list',
            component : _import('appointments/index'),
            meta      : { title : 'sidebar.appointments', icon : 'calendar-check', layout : 'default' },
            props     : true
          }, {
            name      : 'clients-appointments-create',
            path      : '/tenant/:tenant/clients/appointments/add',
            component : _import('appointments/add'),
            hidden    : true,
            meta      : { title : 'main.action.create_appointment', layout : 'default' },
            props     : true
          }, {
            name      : 'clients-appointment-itinerary',
            path      : 'itinerary',
            component : _import('appointments/components/AppointmentTabItinerary'),
            meta      : { title : 'appointment.tab.itinerary', layout : 'default', noScrollTab : true },
            hidden    : true

          }, {
            name      : 'clients-appointment-view',
            path      : '/tenant/:tenant/clients/appointments/:id',
            component : _import('appointments/view'),
            redirect  : 'details',
            meta      : { title : 'sidebar.view', layout : 'default' },
            hidden    : true,
            children  : [
              {
                name      : 'clients-appointment-view-details',
                path      : 'details',
                component : _import('appointments/components/AppointmentTabDetails'),
                meta      : { title : false, layout : 'default', noScrollTab : true },
                hidden    : true
              }, {
                name      : 'clients-appointment-view-clients',
                path      : 'clients',
                component : _import('appointments/components/AppointmentTabClients'),
                meta      : { title : false, layout : 'default', noScrollTab : true },
                hidden    : true
              }
            ]
          }
        ]
      }
    ]
  },
  // *********************
  // ****** MANDATE ******
  // *********************
  {
    name     : 'mandates',
    path     : '/tenant/:tenant/mandates',
    redirect : to => {

      return {
        name   : 'mandates-list',
        params : to.params
      };
    },
    props    : true,
    meta     : { title : 'sidebar.mandates', icon : 'mandate-page', iconSize : 52, layout : 'default' },
    children : [
      {
        name      : 'mandates-list',
        path      : '/tenant/:tenant/mandates/list',
        component : _import('mandates/index'),
        props     : true,
        meta      : { title : 'sidebar.mandatesList', layout : 'default' }
      }, {
        name      : 'mandates-create',
        path      : '/tenant/:tenant/mandates/add',
        component : _import('mandates/add'),
        hidden    : true,
        meta      : { title : 'main.action.create_mandate', layout : 'default' },
        props     : true
      }, {
        name      : 'mandates-view',
        path      : '/tenant/:tenant/mandates/:id',
        redirect  : 'details',
        component : _import('mandates/view'),
        hidden    : true,
        meta      : { title : 'sidebar.view', layout : 'default' },
        children  : [
          {
            name      : 'mandates-view-details',
            path      : 'details',
            component : _import('mandates/components/MandateTabDetails'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'mandates-view-clients',
            path      : 'clients',
            component : _import('mandates/components/MandateTabClients'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'mandates-view-address',
            path      : 'address',
            component : _import('mandates/components/MandateTabAddress'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'mandates-view-documents',
            path      : 'documents',
            component : _import('mandates/components/MandateTabDocuments'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'mandates-view-emandate',
            path      : 'emandate',
            component : _import('mandates/components/MandateTabEmandate'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'mandates-view-template',
            path      : 'template',
            component : _import('mandates/components/MandateTabTemplate'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'mandates-view-issues',
            path      : 'issues',
            component : _import('mandates/components/MandateTabIssues'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'mandates-view-avenants',
            path      : 'avenants',
            component : _import('mandates/components/MandateTabAvenant'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'mandates-view-properties',
            path      : 'properties',
            component : _import('mandates/components/MandateTabProperty'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }, {
            name      : 'mandates-view-commissions',
            path      : 'commissions',
            component : _import('mandates/components/MandateTabCommissions'),
            meta      : { title : false, layout : 'default', noScrollTab : true }
          }
        ]
      },
      {
        name      : 'mandate-avenants',
        path      : '/tenant/:tenant/mandates/avenants/list',
        component : _import('mandates/avenant'),
        meta      : { title : 'sidebar.avenantsList', layout : 'default' },
        props     : true
      }, {
        name     : 'commercialisation',
        path     : '/tenant/:tenant/contracts/commercialisation',
        redirect : () => {

          return {
            name : 'contacts-list'
          };
        },
        meta     : { title : 'sidebar.vefa', layout : 'default', auth : true },
        children :
          [
            {
              name     : 'commercialisation-branches',
              path     : '/tenant/:tenant/commercialisation/branches',
              redirect : '/contracts/commercialisation/branches/list',
              meta     : { title : 'sidebar.branches', layout : 'default', auth : true, hideChildrens : true },
              children :
                [
                  {
                    name      : 'commercialisation-branches-list',
                    path      : '/tenant/:tenant/contracts/commercialisation/branches/list',
                    component : _import('commercialisation/branches/index'),
                    meta      : { icon : 'building', layout : 'default', auth : true }
                  }, {
                    name      : 'branches-create',
                    path      : '/tenant/:tenant/contracts/commercialisation/branches/add',
                    component : _import('commercialisation/branches/add'),
                    hidden    : true,
                    meta      : { title : 'main.action.create', layout : 'default', auth : true },
                    props     : true
                  }, {
                    name      : 'branches-view',
                    path      : '/tenant/:tenant/contracts/commercialisation/branches/:id',
                    redirect  : 'details',
                    component : _import('commercialisation/branches/view'),
                    hidden    : true,
                    meta      : { title : 'sidebar.view', layout : 'default', auth : true },
                    children  :
                    [
                      {
                        name      : 'branches-view-details',
                        path      : 'details',
                        component : _import('commercialisation/branches/components/BranchTabDetails'),
                        meta      : {
                          title       : 'commercialisation.branch.tab.details',
                          layout      : 'default',
                          auth        : true,
                          noScrollTab : true
                        }
                      }, {
                        name      : 'branches-view-contacts',
                        path      : 'contacts',
                        component : _import('commercialisation/branches/components/BranchTabContacts'),
                        meta      : {
                          title       : 'commercialisation.branch.tab.contacts',
                          layout      : 'default',
                          auth        : true,
                          noScrollTab : true
                        }
                      }, {
                        name      : 'branches-view-programs',
                        path      : 'programs',
                        component : _import('commercialisation/branches/components/BranchTabPrograms'),
                        meta      : {
                          title       : 'commercialisation.branch.tab.programs',
                          layout      : 'default',
                          auth        : true,
                          noScrollTab : true
                        }
                      }
                    ]
                  }
                ]
            }, {
              name     : 'commercialisation-developers',
              path     : '/tenant/:tenant/contracts/commercialisation/developers',
              redirect : '/contracts/commercialisation/developers/list',
              meta     : { title : 'sidebar.developers', layout : 'default', auth : true, hideChildrens : true },
              children :
              [
                {
                  name      : 'developers-list',
                  path      : '/tenant/:tenant/contracts/commercialisation/developers/list',
                  component : _import('commercialisation/developers/index'),
                  meta      : { icon : 'building', layout : 'default', auth : true }
                }, {
                  name      : 'developers-create',
                  path      : '/tenant/:tenant/contracts/commercialisation/developers/add',
                  component : _import('commercialisation/developers/add'),
                  hidden    : true,
                  meta      : { title : 'main.action.create', layout : 'default', auth : true },
                  props     : true
                }, {
                  name      : 'developers-view',
                  path      : '/tenant/:tenant/contracts/commercialisation/developers/:id',
                  redirect  : 'details',
                  component : _import('commercialisation/developers/view'),
                  hidden    : true,
                  meta      : { title : 'sidebar.view', layout : 'default', auth : true },
                  children  :
                  [
                    {
                      name      : 'developers-view-details',
                      path      : 'details',
                      component : _import('commercialisation/developers/components/DeveloperTabDetails'),
                      meta      : {
                        title       : false,
                        layout      : 'default',
                        auth        : true,
                        noScrollTab : true
                      }
                    }, {
                      name      : 'developers-view-contracts',
                      path      : 'contracts',
                      component : _import('commercialisation/developers/components/DeveloperTabContracts'),
                      meta      : {
                        title       : false,
                        layout      : 'default',
                        auth        : true,
                        noScrollTab : true
                      }
                    }, {
                      name      : 'developers-view-branches',
                      path      : 'branches',
                      component : _import('commercialisation/developers/components/DeveloperTabBranches'),
                      meta      : {
                        title       : false,
                        layout      : 'default',
                        auth        : true,
                        noScrollTab : true
                      }
                    }, {
                      name      : 'developers-view-contacts',
                      path      : 'contacts',
                      component : _import('commercialisation/developers/components/DeveloperTabContacts'),
                      meta      : {
                        title       : false,
                        layout      : 'default',
                        auth        : true,
                        noScrollTab : true
                      }
                    }, {
                      name      : 'developers-view-clients',
                      path      : 'clients',
                      component : _import('commercialisation/developers/components/DeveloperTabClients'),
                      meta      : {
                        title       : false,
                        layout      : 'default',
                        auth        : true,
                        noScrollTab : true
                      }
                    }, {
                      name      : 'developers-view-programs',
                      path      : 'programs',
                      component : _import('commercialisation/developers/components/DeveloperTabPrograms'),
                      meta      : {
                        title       : false,
                        layout      : 'default',
                        auth        : true,
                        noScrollTab : true
                      }
                    }
                  ]
                }
              ]
            }, {
              name     : 'commercialisation-mandates-co',
              path     : '/tenant/:tenant/contracts/commercialisation/contracts',
              redirect : '/tenant/:tenant/contracts/commercialisation/contracts/list',
              meta     : { title : 'sidebar.contracts', layout : 'default', auth : true, hideChildrens : true },
              children :
              [
                {
                  name      : 'mandates-co-list',
                  path      : '/tenant/:tenant/contracts/commercialisation/contracts/list',
                  component : _import('commercialisation/mandates/index'),
                  meta      : { icon : 'file-alt', layout : 'default', auth : true }
                }, {
                  name      : 'mandates-co-create',
                  path      : '/tenant/:tenant/contracts/commercialisation/contracts/add',
                  component : _import('commercialisation/mandates/add'),
                  hidden    : true,
                  meta      : { title : 'main.action.create', layout : 'default', auth : true },
                  props     : true
                }, {
                  name      : 'mandates-co-view',
                  path      : '/tenant/:tenant/contracts/commercialisation/contracts/:id',
                  redirect  : 'details',
                  component : _import('commercialisation/mandates/view'),
                  hidden    : true,
                  meta      : { title : 'sidebar.view', layout : 'default', auth : true },
                  children  : [
                    {
                      name      : 'mandates-co-view-details',
                      path      : 'details',
                      component : _import('commercialisation/mandates/components/MandateCoTabDetails'),
                      meta      : {
                        title       : 'commercialisation.contract.tab.details',
                        layout      : 'default',
                        auth        : true,
                        noScrollTab : true
                      }
                    }, {
                      name      : 'mandates-co-view-avenants',
                      path      : 'avenants',
                      component : _import('commercialisation/mandates/components/MandateCoTabAvenants'),
                      meta      : {
                        title       : 'commercialisation.contract.tab.avenants',
                        layout      : 'default',
                        auth        : true,
                        noScrollTab : true
                      }
                    }, {
                      name      : 'mandates-co-view-programs',
                      path      : 'programs',
                      component : _import('commercialisation/mandates/components/MandateCoTabPrograms'),
                      meta      : {
                        title       : 'commercialisation.contract.tab.programs',
                        layout      : 'default',
                        auth        : true,
                        noScrollTab : true
                      }
                    }
                  ]
                }
              ]
            }, {
              name     : 'commercialisation-clients',
              path     : '/tenant/:tenant/contracts/commercialisation/clients',
              redirect : '/tenant/:tenant/contracts/commercialisation/clients/list',
              meta     : { title : 'sidebar.clients', layout : 'default', auth : true, hideChildrens : true },
              children :
              [
                {
                  name      : 'clients-list',
                  path      : '/tenant/:tenant/contracts/commercialisation/clients/list',
                  component : _import('commercialisation/clients/index'),
                  meta      : { icon : 'snowplow', layout : 'default', auth : true }
                }, {
                  name      : 'clients-create',
                  path      : '/tenant/:tenant/contracts/commercialisation/clients/add',
                  component : _import('commercialisation/clients/add'),
                  hidden    : true,
                  meta      : { title : 'main.action.create', layout : 'default', auth : true },
                  props     : true
                }
              ]
            }, {
              name     : 'commercialisation-lots',
              path     : '/tenant/:tenant/contracts/commercialisation/lots',
              redirect : '/tenant/:tenant/contracts/commercialisation/lots/list',
              meta     : { title : 'sidebar.lots', layout : 'default', auth : true, hideChildrens : true },
              children :
              [
                {
                  name      : 'lots-list',
                  path      : '/tenant/:tenant/contracts/commercialisation/lots/list',
                  component : _import('commercialisation/lots/index'),
                  meta      : { icon : 'snowplow', layout : 'default', auth : true }
                }
              ]
            }, {
              name     : 'commercialisation-programs',
              path     : '/tenant/:tenant/contracts/commercialisation/programs',
              redirect : '/tenant/:tenant/contracts/commercialisation/programs/list',
              meta     : { title : 'sidebar.programs', layout : 'default', auth : true, hideChildrens : true },
              children :
              [
                {
                  name      : 'programs-list',
                  path      : '/tenant/:tenant/contracts/commercialisation/programs/list',
                  component : _import('commercialisation/programs/index'),
                  meta      : { icon : 'snowplow', layout : 'default', auth : true }
                }, {
                  name      : 'programs-create',
                  path      : '/tenant/:tenant/contracts/commercialisation/programs/add',
                  component : _import('commercialisation/programs/add'),
                  hidden    : true,
                  meta      : { title : 'main.action.create', layout : 'default', auth : true },
                  props     : true
                }, {
                  name      : 'lots-create',
                  path      : '/tenant/:tenant/contracts/commercialisation/lots/add',
                  component : _import('commercialisation/lots/add'),
                  hidden    : true,
                  meta      : { title : 'main.action.createLot', layout : 'default', auth : true },
                  props     : true
                }, {
                  name      : 'programs-view',
                  path      : '/tenant/:tenant/contracts/commercialisation/programs/:id',
                  redirect  : 'details',
                  component : _import('commercialisation/programs/view'),
                  hidden    : true,
                  meta      : { title : 'sidebar.view', layout : 'default', auth : true },
                  children  :
                  [
                    {
                      name      : 'programs-view-details',
                      path      : 'details',
                      component : _import('commercialisation/programs/components/ProgramTabDetails'),
                      meta      : {
                        title       : false,
                        layout      : 'default',
                        auth        : true,
                        noScrollTab : true
                      }
                    }, {
                      name      : 'programs-view-description',
                      path      : 'description',
                      component : _import('commercialisation/programs/components/ProgramTabDescription'),
                      meta      : {
                        title       : false,
                        layout      : 'default',
                        auth        : true,
                        noScrollTab : true
                      }
                    }, {
                      name      : 'programs-view-images',
                      path      : 'images',
                      component : _import('commercialisation/programs/components/ProgramTabImages'),
                      meta      : {
                        title       : false,
                        layout      : 'default',
                        auth        : true,
                        noScrollTab : true
                      }
                    }, {
                      name      : 'programs-view-addresses',
                      path      : 'addresses',
                      component : _import('commercialisation/programs/components/ProgramTabAddresses'),
                      meta      : {
                        title       : false,
                        layout      : 'default',
                        auth        : true,
                        noScrollTab : true
                      }
                    }, {
                      name      : 'programs-view-stages',
                      path      : 'stages',
                      component : _import('commercialisation/programs/components/ProgramTabStages'),
                      meta      : {
                        title       : false,
                        layout      : 'default',
                        auth        : true,
                        noScrollTab : true
                      }
                    }, {
                      name      : 'programs-view-lots',
                      path      : 'lots',
                      component : _import('commercialisation/programs/components/ProgramTabLots'),
                      meta      : {
                        title       : false,
                        layout      : 'default',
                        auth        : true,
                        noScrollTab : true
                      }
                    }, {
                      name      : 'programs-view-contacts',
                      path      : 'contacts',
                      component : _import('commercialisation/programs/components/ProgramTabContacts'),
                      meta      : {
                        title       : false,
                        layout      : 'default',
                        auth        : true,
                        noScrollTab : true
                      }
                    }, {
                      name      : 'programs-view-clients',
                      path      : 'contacts',
                      component : _import('commercialisation/programs/components/ProgramTabClients'),
                      meta      : {
                        title       : false,
                        layout      : 'default',
                        auth        : true,
                        noScrollTab : true
                      }
                    }, {
                      name      : 'programs-view-documents',
                      path      : 'documents',
                      component : _import('commercialisation/programs/components/ProgramTabDocuments'),
                      meta      : {
                        title       : false,
                        layout      : 'default',
                        auth        : true,
                        noScrollTab : true
                      }
                    }
                  ]
                }
              ]
            }, {
              name     : 'commercialisation-bookings',
              path     : '/tenant/:tenant/contracts/commercialisation/bookings',
              redirect : '/tenant/:tenant/contracts/commercialisation/bookings/list',
              meta     : { title : 'sidebar.bookings', layout : 'default', auth : true, hideChildrens : true },
              children :
              [
                {
                  name      : 'bookings-list',
                  path      : '/tenant/:tenant/contracts/commercialisation/bookings/list',
                  component : _import('commercialisation/bookings/index'),
                  meta      : { icon : 'snowplow', layout : 'default', auth : true }
                }
              ]
            }, {
              name     : 'commercialisation-contacts',
              path     : '/tenant/:tenant/contracts/commercialisation/contacts',
              redirect : '/tenant/:tenant/contracts/commercialisation/contacts/list',
              meta     : { title : 'sidebar.contacts', layout : 'default', auth : true, hideChildrens : true },
              children :
              [
                {
                  name      : 'contacts-list',
                  path      : '/tenant/:tenant/contracts/commercialisation/contacts/list',
                  component : _import('commercialisation/contacts/index'),
                  meta      : { icon : 'address-book', layout : 'default', auth : true }
                }, {
                  name      : 'contacts-create',
                  path      : '/tenant/:tenant/contracts/commercialisation/contacts/add',
                  component : _import('commercialisation/contacts/add'),
                  hidden    : true,
                  meta      : { title : 'main.action.create', layout : 'default', auth : true },
                  props     : true
                }, {
                  name      : 'contacts-view',
                  path      : '/tenant/:tenant/contracts/commercialisation/contacts/:id',
                  redirect  : 'details',
                  component : _import('commercialisation/contacts/view'),
                  hidden    : true,
                  meta      : { title : 'sidebar.view', layout : 'default', auth : true },
                  children  : [
                    {
                      name      : 'contacts-view-details',
                      path      : 'details',
                      component : _import('commercialisation/contacts/components/ContactTabDetails'),
                      meta      : {
                        title       : false,
                        layout      : 'default',
                        auth        : true,
                        noScrollTab : true
                      }
                    }, {
                      name      : 'contacts-view-programs',
                      path      : 'programs',
                      component : _import('commercialisation/contacts/components/ContactTabPrograms'),
                      meta      : {
                        title       : false,
                        layout      : 'default',
                        auth        : true,
                        noScrollTab : true
                      }
                    }
                  ]
                }
              ]
            }
          ]
      }
    ]
  },

  // *********************
  // ******  ISSUE  ******
  // *********************
  {
    name     : 'threads',
    path     : '/tenant/:tenant/threads',
    redirect : to => {

      return {
        name   : 'threads-issues-list',
        params : to.params
      };
    },
    meta     : {
      title : 'sidebar.issues', icon : 'issue-erreur-brown', iconSize : 96, layout : 'default', hideChildrens : true
    },
    children : [
      {
        name      : 'threads-issues-list',
        path      : '/tenant/:tenant/threads/issues/list',
        component : _import('thread/index'),
        meta      : { title : 'thread.issueList', layout : 'default' },
        hidden    : true,
        props     : true
      }, {
        name      : 'threads-view',
        path      : '/tenant/:tenant/threads/:id',
        component : _import('thread/view'),
        meta      : { layout : 'default' },
        hidden    : true
      }
    ]
  },
  // *********************
  // ******  AGENDA ******
  // *********************
  {
    name     : 'toolbox',
    path     : '/tenant/:tenant/toolbox',
    hidden   : true,
    redirect : () => {

      return {
        name : 'toolbox-calendar'
      };
    },
    meta     : { title : 'sidebar.toolbox', icon : 'calendar', layout : 'default' },
    children : [
      {
        name      : 'toolbox-fees-calculator',
        path      : '/tenant/:tenant/toolbox/fees-calculator',
        component : _import('fees_calculator/view'),
        meta      : { title : 'sidebar.feesCalculator', icon : 'calculator', layout : 'default' }
      }, {
        name      : 'toolbox-calendar',
        path      : '/tenant/:tenant/toolbox/calendar/list',
        component : _import('calendar/index'),
        meta      : { title : 'sidebar.calendar', icon : 'calendar', layout : 'default' }
      }, {
        name     : 'toolbox-contacts',
        path     : '/tenant/:tenant/toolbox/contacts',
        redirect : () => {

          return {
            name : 'toolbox-agents-map'
          };
        },
        meta     : { title : 'sidebar.contact_list', icon : 'map', layout : 'default' },
        children : [
          {

            name      : 'toolbox-agents-map',
            path      : '/tenant/:tenant/toolbox/contacts/agents-map',
            component : _import('contacts/agents'),
            props     : { switchStart : 'map' },
            meta      : { title : 'sidebar.agents_map', icon : 'map', layout : 'default' }
          }, {
            name      : 'toolbox-agents-list',
            path      : '/tenant/:tenant/toolbox/contacts/agents-list',
            component : _import('contacts/agents'),
            props     : { switchStart : 'list' },
            meta      : { title : 'sidebar.agents_list', icon : 'user', layout : 'default' }
          }, {
            name      : 'toolbox-agencies-list',
            path      : '/tenant/:tenant/toolbox/contacts/agencies',
            component : _import('contacts/agencies'),
            props     : { switchStart : 'list' },
            meta      : { title : 'sidebar.agencies', icon : 'company', layout : 'default' }
          }, {
            name      : 'toolbox-agencies-map',
            path      : '/tenant/:tenant/toolbox/contacts/agencies-map',
            component : _import('contacts/agencies'),
            props     : { switchStart : 'map' },
            meta      : { title : 'sidebar.agencies_map', icon : 'map', layout : 'default' }
          }
        ]
      }, {
        name      : 'toolbox-potential_buyers',
        path      : '/tenant/:tenant/toolbox/potential-buyers',
        component : _import('potential_buyers/index'),
        meta      : { title : 'sidebar.potentialBuyers', icon : 'potential_buyers', layout : 'default' }
      },
      {
        name     : 'usefull-link',
        path     : '/tenant/:tenant/usefullLink',
        hidden   : true,
        meta     : { title : 'sidebar.usefullLink', icon : 'link', layout : 'default' },
        children : []
      }, {
        name      : 'toolbox-portfolio',
        path      : '/tenant/:tenant/toolbox/portfolio',
        component : _import('portfolio/index'),
        meta      : { title : 'sidebar.portfolio', icon : 'link', layout : 'default' }
      }
    ]
  },
  // *********************
  // ******  AGENDA ******
  // *********************

  // *********************
  // ***** MY PROFILE ****
  // *********************
  {
    name      : 'my-profile-view',
    path      : '/tenant/:tenant/users/:id',
    redirect  : 'details',
    component : _import('users/view'),
    props     : { type : 'my-profile' },
    meta      : { title : 'sidebar.users', layout : 'default', hideChildrens : true },
    hidden    : true,
    children  : [
      {
        name      : 'my-profile-view-details',
        path      : 'details',
        component : _import('users/components/UserTabDetails'),
        meta      : { title : 'profile.details', layout : 'default', noScrollTab : true },
        hidden    : true
      }, {
        name      : 'my-profile-view-phones',
        path      : 'phones',
        component : _import('users/components/UserTabPhones'),
        meta      : { title : 'profile.phones', layout : 'default', noScrollTab : true },
        hidden    : true
      }, {
        name      : 'my-profile-view-languages',
        path      : 'languages',
        component : _import('users/components/UserTabLanguages'),
        meta      : { title : 'profile.languages', icon : 'cogs', layout : 'default', noScrollTab : true },
        hidden    : true
      }, {
        name      : 'my-profile-view-settings',
        path      : 'settings',
        component : _import('users/components/UserTabSettings'),
        meta      : { title : 'profile.settings', icon : 'cogs', layout : 'default', noScrollTab : true },
        hidden    : true
      }, {
        name      : 'my-profile-view-templates',
        path      : 'templates',
        component : _import('users/components/UserTabTemplates'),
        meta      : { title : 'profile.templates', icon : 'file-alt', layout : 'default', noScrollTab : true },
        hidden    : true
      }, {
        name      : 'my-profile-view-timemachine',
        path      : 'timemachine',
        component : _import('users/components/UserTabTimemachine'),
        meta      : { title : 'profile.templates', layout : 'default', noScrollTab : true },
        hidden    : true
      }, {
        name      : 'my-profile-view-notifications',
        path      : 'notifications',
        component : _import('users/components/UserTabNotifications'),
        meta      : { title : 'profile.notifications', layout : 'default', noScrollTab : true },
        hidden    : true
      }, {
        name      : 'my-profile-view-communication-preferences',
        path      : 'communication-preferences',
        component : _import('users/components/UserTabCommunicationPreferences'),
        meta      : { title : 'profile.communicationPreferences', layout : 'default', noScrollTab : true },
        hidden    : true
      }
    ]
  },
  // *********************
  // ******  ADMIN  ******
  // *********************
  {
    path      : '/tenant/:tenant/',
    component : _import('dashboard/index'),
    name      : 'back_home',
    meta      : { title : 'sidebar.exitAdministration', icon : 'long-arrow-alt-left', layout : 'admin' }
  },
  {
    path      : '/tenant/:tenant/admin/dashboard',
    component : _import('admin/dashboard'),
    name      : 'dashboard_admin',
    meta      : {
      title  : 'sidebar.admin', icon   : 'tachometer-alt', layout : 'admin',
      roles  : RBAC.roles.filter((role) => role.startsWith('admin_') || role.endsWith('_admin'))
    }
  },

  // *********************
  // ***GENERAL SETTINGS *
  {
    path     : '/tenant/:tenant/admin/settings',
    meta     : {
      title  : 'sidebar.generalSettings', icon   : 'cogs', layout : 'admin',
      roles  : ['super_admin', 'admin_hestia', 'admin_acl']
    },
    children : [
      {
        name      : 'settings_general_information',
        path      : '/tenant/:tenant/admin/settings/general_information',
        component : _import('general_settings/general_information'),
        meta      : {
          title  : 'sidebar.generalInformation', icon   : 'cogs', layout : 'admin',
          roles  : ['super_admin', 'admin_hestia']
        }
      },
      {
        name      : 'settings_access_control',
        path      : '/tenant/:tenant/admin/settings/access_control_list',
        component : _import('general_settings/access_control'),
        meta      : {
          title  : 'sidebar.accessControl', icon   : 'universal-access', layout : 'admin',
          roles  : ['super_admin', 'admin_hestia', 'admin_acl']
        }
      }, {
        name      : 'settings_email_config',
        path      : '/tenant/:tenant/admin/settings/email_config',
        component : _import('general_settings/email_config'),
        meta      : {
          title : 'sidebar.emailConfig', icon : 'at', layout : 'admin', roles : ['super_admin', 'admin_hestia']
        }
      },
      {
        name      : 'settings_reply_to_email_config',
        path      : '/tenant/:tenant/admin/settings/reply_to_email_config',
        component : _import('general_settings/reply_to_email_config'),
        meta      : {
          title : 'sidebar.replyToEmailConfig', icon : 'at', layout : 'admin', roles : ['super_admin', 'admin_hestia']
        }
      },
      {
        name      : 'settings_tenant_languages',
        path      : '/tenant/:tenant/admin/settings/tenant_languages',
        component : _import('general_settings/tenant_languages'),
        meta      : {
          title : 'sidebar.tenantLanguages', icon : 'cogs', layout : 'admin', roles : ['super_admin', 'admin_hestia']
        }
      }, {
        name      : 'settings_providers',
        path      : '/tenant/:tenant/admin/settings/providers',
        component : _import('general_settings/providers'),
        meta      : {
          title : 'sidebar.providers', icon : 'cogs', layout : 'admin', roles : ['super_admin', 'admin_hestia']
        }
      }, {
        name      : 'settings_agencies',
        path      : '/tenant/:tenant/admin/settings/agencies',
        component : _import('general_settings/agencies'),
        meta      : {
          title : 'sidebar.agencies', icon : 'building', layout : 'admin', roles : ['super_admin', 'admin_hestia']
        }
      }, {
        name      : 'usefull_link',
        path      : '/tenant/:tenant/admin/settings/usefull_link',
        component : _import('general_settings/usefull_link'),
        meta      : {
          title : 'sidebar.usefullLink', icon : 'link', layout : 'admin', roles : ['super_admin', 'admin_hestia']
        }
      }, {
        name      : 'gdpr',
        path      : '/tenant/:tenant/admin/settings/gdpr',
        component : _import('general_settings/gdpr'),
        meta      : {
          title : 'sidebar.gdpr', icon : 'gavel', layout : 'admin', roles : ['super_admin', 'admin_hestia']
        }
      }, {
        name      : 'alert',
        path      : '/tenant/:tenant/admin/settings/alert',
        component : _import('general_settings/alert'),
        meta      : {
          title : 'sidebar.alert', icon : 'cogs', layout : 'admin', roles : ['super_admin', 'admin_hestia']
        }
      }
    ]
  },
  // *********************
  // ******* MAIL ********
  // *********************
  {
    path     : '/tenant/:tenant/admin/mail',
    meta     : {
      title : 'sidebar.mail', icon : 'mail', layout : 'admin', roles : ['super_admin', 'admin_hestia'], hidden : true
    },
    children : [
      {
        name      : 'mail_view',
        path      : '/tenant/:tenant/admin/mail/:id',
        component : _import('email/view'),
        meta      : { layout : 'admin', roles : ['super_admin', 'admin_hestia'] },
        props     : true
      }
    ]
  },
  // *********************
  // *** PROPERTIES ******
  {
    path     : '/tenant/:tenant/admin/properties',
    meta     : {
      title  : 'sidebar.properties',
      icon   : 'home',
      layout : 'admin',
      roles  : ['super_admin', 'admin_property_types', 'admin_property_categories', 'admin_property_features', 'admin_hestia']
    },
    children : [
      {
        name      : 'property_categories',
        path      : '/tenant/:tenant/admin/properties/categories',
        component : _import('properties/category'),
        meta      : {
          title  : 'sidebar.categories', icon   : 'cog', layout : 'admin',
          roles  : ['super_admin', 'admin_hestia', 'admin_property_categories']
        }
      }, {
        name      : 'property_features',
        path      : '/tenant/:tenant/admin/properties/features',
        component : _import('properties/feature'),
        meta      : {
          title  : 'sidebar.features', icon   : 'cogs', layout : 'admin',
          roles  : ['super_admin', 'admin_hestia', 'admin_property_types']
        }
      }, {
        name      : 'property_primary_types',
        path      : '/tenant/:tenant/admin/properties/primary_types',
        component : _import('properties/primary_types'),
        meta      : {
          title  : 'sidebar.primary_types', icon   : 'bookmark', layout : 'admin',
          roles  : ['super_admin', 'admin_hestia', 'admin_property_types']
        }
      }, {
        name      : 'secondary_secondary_types',
        path      : '/tenant/:tenant/admin/properties/secondary_types',
        component : _import('properties/secondary_types'),
        meta      : {
          title  : 'sidebar.secondary_types',
          icon   : 'bookmark',
          layout : 'admin',
          roles  : ['super_admin', 'admin_property_types', 'admin_hestia']
        }
      }, {
        name      : 'property_offer',
        path      : '/tenant/:tenant/admin/properties/offers',
        component : _import('properties/offer/template'),
        meta      : {
          title  : 'sidebar.templateOffer',
          icon   : 'money-bill',
          layout : 'admin',
          roles  : ['super_admin', 'admin_property_offers', 'admin_hestia']
        }
      }, {
        name      : 'offer_elia',
        path      : '/tenant/:tenant/admin/properties/offers/elia',
        component : _import('properties/offer/elia/template'),
        meta      : {
          title  : 'sidebar.eliaTemplate', icon   : 'paper-plane', layout : 'admin',
          roles  : ['super_admin', 'admin_hestia']
        }
      }, {
        name      : 'property_source',
        path      : '/tenant/:tenant/admin/properties/sources',
        component : _import('properties/sources'),
        meta      : {
          title  : 'sidebar.sources', icon   : 'ad', layout : 'admin',
          roles  : ['super_admin', 'admin_hestia', 'admin_property_sources']
        }
      }, {
        name      : 'property_brochure',
        path      : '/tenant/:tenant/admin/properties/brochures',
        component : _import('properties/brochures'),
        meta      : {
          title  : 'sidebar.brochure', icon   : 'file-pdf', layout : 'admin',
          roles  : ['super_admin', 'admin_hestia', 'admin_property_brochures']
        }
      }, {
        name      : 'property_attestation_no_dpe',
        path      : '/tenant/:tenant/admin/properties/attestation_no_dpe',
        component : _import('properties/no_dpe_attestation/template'),
        meta      : {
          title : 'sidebar.templateNoDpe', icon : 'file-alt', layout : 'admin', roles : ['super_admin', 'admin_hestia']
        }
      }, {
        name      : 'property_service_hoqi',
        path      : '/tenant/:tenant/admin/properties/hoqi',
        component : _import('properties/service_hoqi'),
        meta      : {
          title : 'sidebar.serviceHoqi', icon : 'magic', layout : 'admin', roles : ['super_admin', 'admin_hestia']
        }
      }, {
        name      : 'property_template_overview',
        path      : '/tenant/:tenant/admin/properties/template_overview',
        component : _import('properties/templateOverview'),
        meta      : {
          title  : 'sidebar.templateOverview', icon   : 'file-alt', layout : 'admin',
          roles  : ['super_admin', 'admin_hestia']
        }
      }
    ]
  },
  // *********************
  // ***** MANDATES ******
  {
    path     : '/tenant/:tenant/admin/mandates',
    meta     : {
      title  : 'sidebar.mandates', icon   : 'bookmark', layout : 'admin', roles  : [
        'super_admin', 'admin_hestia', 'admin_mandate_type', 'admin_mandate_template',
        'admin_mandate_commission'
      ]
    },
    children : [
      {
        path      : '/tenant/:tenant/admin/mandates/types',
        component : _import('mandates/type'),
        meta      : {
          title  : 'sidebar.types', icon   : 'bookmark', layout : 'admin',
          roles  : ['super_admin', 'admin_hestia', 'admin_mandate_type']
        }
      }, {
        name      : 'registry',
        path      : '/tenant/:tenant/admin/mandates/registry',
        component : _import('mandates/registry/index'),
        meta      : {
          title : 'sidebar.registry', icon : 'table', layout : 'admin', roles : ['super_admin', 'admin_hestia', 'admin_mandate_type']
        }
      }, {
        name      : 'commissions',
        path      : '/tenant/:tenant/admin/commissions',
        component : _import('commissions/index'),
        meta      : {
          title  : 'sidebar.commissions',
          icon   : 'hand-holding-usd',
          layout : 'admin',
          roles  : ['super_admin', 'admin_hestia', 'admin_mandate_commission']
        }
      }, {
        name      : 'avenants',
        path      : '/tenant/:tenant/admin/mandates/avenants',
        component : _import('mandates/avenant/DefaultAvenantTemplate'),
        meta      : {
          title  : 'sidebar.avenantTemplate', icon   : 'file-alt', layout : 'admin',
          roles  : ['super_admin', 'admin_hestia']
        }
      }, {
        name      : 'eavenants',
        path      : '/tenant/:tenant/admin/mandates/eavenants',
        component : _import('mandates/eavenant/template'),
        meta      : {
          title  : 'sidebar.eavenantTemplate', icon   : 'paper-plane', layout : 'admin',
          roles  : ['super_admin', 'admin_hestia']
        }
      }, {
        name      : 'emandates',
        path      : '/tenant/:tenant/admin/mandates/emandates',
        component : _import('mandates/emandate/template'),
        meta      : {
          title  : 'sidebar.emandateTemplate', icon   : 'paper-plane', layout : 'admin',
          roles  : ['super_admin', 'admin_hestia']
        }
      }, {
        name      : 'timemachine',
        path      : '/tenant/:tenant/admin/mandates/timemachine',
        component : _import('mandates/timemachine/index'),
        meta      : {
          title : 'sidebar.timemachine', icon : 'backward', layout : 'admin', roles : ['super_admin', 'admin_hestia']
        }
      }
    ]
  },
  // *********************
  // ***** CLIENT ******
  {
    path     : '/tenant/:tenant/admin/client',
    meta     : {
      title  : 'sidebar.clients', icon   : 'users', layout : 'admin', auth   : true, admin  : true,
      roles  : ['super_admin', 'admin_hestia', 'admin', 'admin_client_template']
    },
    children : [
      {
        name      : 'flags-list',
        path      : '/tenant/:tenant/admin/client/flags',
        component : _import('clients/flags'),
        meta      : {
          title  : 'sidebar.flags', icon   : 'flag', layout : 'admin', auth   : true, admin  : true,
          roles  : ['super_admin', 'admin_hestia', 'admin']
        }
      }, {
        name      : 'mailing-view-template',
        path      : '/tenant/:tenant/admin/client/mailing/templates',
        component : _import('clients/template'),
        meta      : {
          title  : 'sidebar.mailing_templates', icon   : 'paper-plane', layout : 'admin', auth   : true, admin  : true,
          roles  : ['super_admin', 'admin_hestia', 'admin', 'admin_client_template']
        }
      }
    ]
  },
  // *********************
  // ***** VEFA ******
  {
    path     : '/tenant/:tenant/admin/commercialisation',
    meta     : {
      title  : 'sidebar.vefa', icon   : 'building', layout : 'admin', auth   : true, admin  : true,
      roles  : ['super_admin', 'admin_hestia', 'admin']
    },
    children : [
      {
        name      : 'eligibilities-list',
        path      : '/tenant/:tenant/admin/contracts/commercialisation/eligibilities',
        component : _import('commercialisation/eligibilities/index'),
        meta      : {
          title  : 'sidebar.eligibilities',
          icon   : 'percentage',
          layout : 'admin',
          auth   : true,
          admin  : true,
          roles  : ['super_admin', 'admin_hestia', 'admin']
        }
      }
    ]
  },
  // *********************
  // ***** REQUESTS    ******
  {
    name     : 'admin-requests',
    path     : '/tenant/:tenant/admin/requests',
    meta     : {
      title : 'sidebar.requests', icon : 'comments', layout : 'admin', roles : ['super_admin', 'admin_hestia']
    },
    children : [
      {
        name      : 'requests-sources',
        path      : '/tenant/:tenant/admin/requests/sources',
        component : _import('request/source'),
        meta      : {
          title : 'sidebar.sources', icon : 'tags', layout : 'admin', roles : ['super_admin', 'admin_hestia']
        }
      }, {
        name      : 'requests-inbounds',
        path      : '/tenant/:tenant/admin/requests/inbounds',
        component : _import('request/inbound'),
        meta      : {
          title : 'sidebar.inbounds', icon : 'reply', layout : 'admin', roles : ['super_admin', 'admin_hestia']
        }
      }, {
        name      : 'requests-received',
        path      : '/tenant/:tenant/admin/requests/received',
        component : _import('request/received'),
        meta      : {
          title : 'sidebar.received', icon : 'reply', layout : 'admin', roles : ['super_admin', 'admin_hestia']
        }
      }
    ]
  },
  // *********************
  // ***** APPOINTMENT ******
  {
    path     : '/tenant/:tenant/admin/appointments',
    meta     : {
      title  : 'sidebar.appointments', icon   : 'calendar', layout : 'admin',
      roles  : ['super_admin', 'admin_hestia', 'admin_appointments_bdv']
    },
    children : [
      {
        name      : 'appointment_bdv',
        path      : '/tenant/:tenant/admin/appointments/bdv',
        component : _import('appointments/bdv/template'),
        meta      : {
          title  : 'sidebar.bdv', icon   : 'calendar-check', layout : 'admin',
          roles  : ['super_admin', 'admin_hestia', 'admin_appointments_bdv']
        }
      }, {
        name      : 'appointment_send',
        path      : '/tenant/:tenant/admin/appointments/sendAppointment',
        component : _import('appointments/send'),
        meta      : {
          title  : 'sidebar.sendAppointment',
          icon   : 'paper-plane',
          layout : 'admin',
          roles  : ['super_admin', 'admin_hestia', 'admin_bdv_template']
        }
      }
    ]
  },
  // *********************
  // ***** USERS    ******
  {
    name     : 'admin-users-groups',
    path     : '/tenant/:tenant/admin/users-groups',
    redirect : '/tenant/:tenant/admin/users/list',
    meta     : {
      title  : 'sidebar.usersAndGroups', icon   : 'user', layout : 'admin',
      roles  : ['super_admin', 'admin_hestia', 'admin_user', 'admin_groups']
    },
    children : [
      {
        name     : 'admin-users',
        path     : '/tenant/:tenant/admin/users',
        redirect : '/tenant/:tenant/admin/users/list',
        meta     : {
          title         : 'sidebar.users', icon          : 'user', layout        : 'admin', hideChildrens : true,
          roles         : ['super_admin', 'admin_hestia', 'admin_user']
        },
        children : [
          {
            name      : 'admin-users-list',
            path      : '/tenant/:tenant/admin/users/list',
            component : _import('users/index'),
            meta      : {
              title         : 'sidebar.users',
              icon          : 'user',
              layout        : 'admin',
              hideChildrens : true,
              roles         : ['super_admin', 'admin_hestia', 'admin_user']
            }
          }, {
            name      : 'admin-users-view',
            path      : '/tenant/:tenant/admin/users/:id',
            redirect  : 'details',
            component : _import('users/view'),
            props     : true,
            meta      : {
              title         : 'main.action.view', layout        : 'admin', hideChildrens : true,
              roles         : ['super_admin', 'admin_hestia', 'admin_user']
            },
            hidden    : true,
            children  : [
              {
                name      : 'admin-users-view-details',
                path      : 'details',
                component : _import('users/components/UserTabDetails'),
                meta      : {
                  title : 'profile.details', layout : 'admin', roles : ['super_admin', 'admin_hestia', 'admin_user']
                },
                hidden    : true
              }, {
                name      : 'admin-users-view-phones',
                path      : 'phones',
                component : _import('users/components/UserTabPhones'),
                meta      : {
                  title : 'profile.phones', layout : 'admin', roles : ['super_admin', 'admin_hestia', 'admin_user']
                },
                hidden    : true
              }, {
                name      : 'admin-users-view-timemachine',
                path      : 'timemachine',
                component : _import('users/components/UserAdminTabTimemachine'),
                meta      : {
                  title : 'profile.timemachine', layout : 'admin', roles : ['super_admin', 'admin_hestia', 'admin_user']
                },
                hidden    : true
              }, {
                name      : 'admin-users-view-about',
                path      : 'about',
                component : _import('users/components/UserTabAbout'),
                meta      : {
                  title : 'user.about', layout : 'admin', roles : ['super_admin', 'admin_hestia', 'admin_user']
                },
                hidden    : true
              }, {
                name      : 'admin-users-view-admin',
                path      : 'admin',
                component : _import('users/components/UserTabAdmin'),
                meta      : {
                  title : 'profile.admin', layout : 'admin', roles : ['super_admin', 'admin_hestia', 'admin_user']
                },
                hidden    : true
              },
              {
                name      : 'admin-users-view-comment',
                path      : 'admin',
                component : _import('users/components/UserTabComment'),
                meta      : {
                  title : 'profile.comment', layout : 'admin', roles : ['super_admin', 'admin_hestia', 'admin_user']
                },
                hidden    : true
              }, {
                name      : 'admin-users-view-notifications',
                path      : 'notifications',
                component : _import('users/components/UserTabNotifications'),
                meta      : {
                  title  : 'profile.notifications', layout : 'admin',
                  roles  : ['super_admin', 'admin_hestia', 'admin_user']
                },
                hidden    : true
              }, {
                name      : 'admin-users-view-communication-preferences',
                path      : 'communication-preferences',
                component : _import('users/components/UserTabCommunicationPreferences'),
                meta      : { title : 'profile.communicationPreferences', layout : 'admin', noScrollTab : true },
                hidden    : true
              }
            ]
          }
        ]
      }, {
        name      : 'groups',
        path      : '/tenant/:tenant/admin/groups',
        component : _import('groups/index'),
        meta      : {
          title  : 'sidebar.groups', icon   : 'users', layout : 'admin',
          roles  : ['super_admin', 'admin_hestia', 'admin_group']
        }
      }
    ]
  },
  // *********************
  // ***** GROUPS   ******
  {
    path     : '/tenant/:tenant/admin/threads',
    meta     : {
      title  : 'sidebar.threads',
      icon   : 'question',
      layout : 'admin',
      roles  : ['super_admin', 'admin_hestia', 'admin_message_label', 'admin_message_template']
    },
    children : [
      {
        path      : '/tenant/:tenant/admin/thread/label',
        component : _import('thread/label'),
        meta      : {
          title  : 'sidebar.labels', icon   : 'tags', layout : 'admin',
          roles  : ['super_admin', 'admin_hestia', 'admin_message_label']
        }
      }, {
        name      : 'admin-thread-template',
        path      : '/tenant/:tenant/admin/thread/template',
        component : _import('thread/template'),
        meta      : {
          title  : 'sidebar.template', icon   : 'file-alt', layout : 'admin',
          roles  : ['super_admin', 'admin_hestia', 'admin_message_template']
        }
      }
    ]
  },
  // *********************
  // ***** DOCUMENTS *****
  {
    path     : '/tenant/:tenant/admin/documents',
    meta     : {
      title : 'sidebar.documents', icon : 'question', layout : 'admin', roles : ['super_admin', 'admin_hestia']
    },
    children : [
      {
        path      : '/tenant/:tenant/admin/documents/type',
        component : _import('documents/type'),
        meta      : {
          title : 'sidebar.types', icon : 'tags', layout : 'admin', roles : ['super_admin', 'admin_hestia']
        }
      }
    ]
  },
  // *********************
  // ***** OTHERS   ******
  {
    name      : 'admin_docusign',
    path      : '/tenant/:tenant/admin/docusign',
    component : _import('admin/docusign'),
    meta      : {
      title  : 'sidebar.docusign', icon   : 'file-signature', layout : 'admin',
      roles  : ['super_admin', 'admin_hestia', 'admin']
    }

  },
  {
    path      : '/tenant/:tenant/admin/audit-event-list',
    component : _import('audit/index'),
    name      : 'audit',
    meta      : {
      title  : 'sidebar.audit', icon   : 'bookmark', layout : 'admin',
      roles  : ['super_admin', 'admin_hestia', 'admin_audit']
    }
  },
  // *********************
  // ***** PARTNERS    ******
  {
    name     : 'admin-partners-service',
    path     : '/tenant/:tenant/admin/partners-service',
    redirect : '/tenant/:tenant/admin/partners/list',
    meta     : { title : 'sidebar.partners', icon : 'user', layout : 'admin', roles : ['super_admin', 'admin_hestia'] },
    children : [
      {
        name     : 'admin-partners',
        path     : '/tenant/:tenant/admin/partners',
        redirect : '/tenant/:tenant/admin/partners/list',
        meta     : {
          title         : 'sidebar.partners', icon          : 'user', layout        : 'admin', hideChildrens : true,
          roles         : ['super_admin', 'admin_hestia']
        },
        children : [
          {
            name      : 'admin-partners-list',
            path      : '/tenant/:tenant/admin/partners/list',
            component : _import('partner/index'),
            meta      : {
              title         : 'sidebar.partners', icon          : 'user', layout        : 'admin', hideChildrens : true,
              roles         : ['super_admin', 'admin_hestia']
            }
          }, {
            name      : 'admin-partners-view',
            path      : '/tenant/:tenant/admin/partners/:id',
            redirect  : 'details',
            component : _import('partner/view'),
            meta      : {
              title         : 'main.action.view', layout        : 'admin', hideChildrens : true,
              roles         : ['super_admin', 'admin_hestia']
            },
            hidden    : true,
            children  : [
              {
                name      : 'admin-partners-view-details',
                path      : 'details',
                component : _import('partner/components/PartnerTabDetails'),
                meta      : { title : 'partner.details', layout : 'admin', roles : ['super_admin', 'admin_hestia'] },
                hidden    : true
              }, {
                name      : 'admin-partners-view-documents',
                path      : 'documents',
                component : _import('partner/components/PartnerTabDocuments'),
                meta      : { title : 'partner.documents', layout : 'admin', roles : ['super_admin', 'admin_hestia'] },
                hidden    : true
              }
            ]
          }
        ]
      }, {
        name      : 'admin-importers',
        path      : '/tenant/:tenant/admin/partners-importers',
        component : _import('partner/importers/index'),
        meta      : {
          title : 'sidebar.importers', icon : 'file-alt', layout : 'admin', roles : ['super_admin', 'admin_hestia']
        }
      }
    ]
  },
  // *********************
  // ***** APPORTEUR    ******
  {
    name     : 'apporteur-service',
    path     : '/tenant/:tenant/apporteur-service',
    redirect : '/tenant/:tenant/apporteur/list',
    hidden   : true,
    meta     : { title : 'sidebar.apporteur', layout : 'default' },
    children : [
      {
        name      : 'apporteur-list',
        path      : '/tenant/:tenant/apporteur/list',
        component : _import('apporteur/index'),
        meta      : { title : false, layout : 'default' },
        hidden    : true
      }, {
        name      : 'apporteur-template',
        path      : '/tenant/:tenant/apporteur/template',
        component : _import('apporteur/template'),
        hidden    : true,
        meta      : {
          title  : false,
          layout : 'default'
        }
      }
    ]
  },
// *********************
  {
    name      : 'notification-list',
    path      : '/tenant/:tenant/notification/list',
    component : _import('notification/index'),
    hidden    : true,
    meta      : { title : 'notification.list', layout : 'default' }
  }, {
    path      : '*',
    component : _import('error/404'),
    hidden    : true,
    meta      : { title : '404', icon : 'tags', layout : 'default' }
  },
  // *********************
  // ***** WALLET ******
  {
    name     : 'admin-wallet',
    path     : '/tenant/:tenant/admin/wallet',
    redirect : '/tenant/:tenant/admin/wallet/payment-service',
    meta     : { title : 'sidebar.wallet', icon : 'wallet', layout : 'admin', roles : ['super_admin', 'admin_hestia'] },
    children : [
      {
        name      : 'admin-wallet-payment-service',
        path      : '/tenant/:tenant/admin/wallet/payment-service',
        component : _import('wallet/payment_service'),
        meta      : {
          title  : 'sidebar.paymentService', icon   : 'credit-card', layout : 'admin',
          roles  : ['super_admin', 'admin_hestia']
        }
      },
      {
        name      : 'admin-wallet-credits-parameters',
        path      : '/tenant/:tenant/admin/wallet/credits-parameters',
        component : _import('wallet/credits_parameters'),
        meta      : {
          title : 'sidebar.creditsParameters', icon : 'cog', layout : 'admin', roles : ['super_admin', 'admin_hestia']
        }
      },
      {
        name      : 'admin-wallet-invoice-template',
        path      : '/tenant/:tenant/admin/wallet/invoice-template',
        component : _import('wallet/invoice_template'),
        meta      : {
          title  : 'sidebar.invoiceTemplate', icon   : 'file-alt', layout : 'admin',
          roles  : ['super_admin', 'admin_hestia']
        }
      },
      {
        name      : 'admin-wallet-credits-send',
        path      : '/tenant/:tenant/admin/wallet/credits-send',
        component : _import('wallet/credits_send'),
        meta      : {
          title  : 'sidebar.creditsSend', icon   : 'hand-holding-usd', layout : 'admin',
          roles  : ['super_admin', 'admin_hestia']
        }
      }
    ]
  },

  // *********************
  // ***** MARKETPLACE ******
  {
    name      : 'marketplace',
    path      : '/tenant/:tenant/leggett-marketing-hub',
    component : _import('marketplace/cart'),
    hidden    : true,
    children  : [
      {
        name      : 'shopping-cart',
        path      : '/tenant/:tenant/leggett-marketing-hub/cart',
        component : _import('marketplace/cart'),
        hidden    : true,
        children  : [],
        meta      : { title : 'marketplace.cart.title', layout : 'default' }
      }
    ],
    meta      : { title : 'marketplace.title', layout : 'default' }
  },
  {
    name     : 'admin-marketplace',
    path     : '/tenant/:tenant/admin/leggett-marketing-hub',
    redirect : '/tenant/:tenant/admin/leggett-marketing-hub/products',
    meta     : {
      title      : 'sidebar.marketplace', customIcon : 'client-representative-add', layout     : 'admin',
      roles      : ['super_admin', 'admin_hestia', 'admin_marketplace']
    },
    children : [
      {
        name      : 'admin-marketplace-products',
        path      : '/tenant/:tenant/admin/leggett-marketing-hub/products',
        component : _import('marketplace/products'),
        meta      : {
          title      : 'sidebar.marketplace', customIcon : 'client-representative-add', layout     : 'admin',
          roles      : ['super_admin', 'admin_hestia', 'admin_marketplace']
        }
      }
    ]
  },
// *********************
  {
    name      : 'notification-list',
    path      : '/tenant/:tenant/notification/list',
    component : _import('notification/index'),
    hidden    : true,
    meta      : { title : 'notification.list', layout : 'default' }
  },
  {
    name      : 'wallet',
    path      : '/tenant/:tenant/wallet',
    component : _import('wallet/index'),
    meta      : { title : 'wallet.title', layout : 'default' }
  },
  {
    path      : '*',
    component : _import('error/404'),
    hidden    : true,
    meta      : { title : '404', icon : 'tags', layout : 'default' }
  }
];

export const asyncRouterMap = [];

const router = new Router({
  mode   : 'history',
  routes : allowEmptyParent(fixedRouterMap),
  scrollBehavior(to, from, savedPosition) { //Auto Scroll to top expect for Tabs

    if (to.meta.noScrollTab && from.meta.noScrollTab) {
      return savedPosition;
    }

    return { x : 0, y : 0 };

  },
  parseQuery(query) {

    return qs.parse(query);
  },
  stringifyQuery(query) {

    const result = qs.stringify(query);

    return result ? ('?' + result) : '';
  }
});

document.addEventListener(
  'serviceWorkerUpdateEvent', () => {

  }, { once : true }
);

/**
 * Add tenantId in parameters if it's not filled in
 * If it's missing, change route with "tenant/:tenantId" if there's tenant in the store
 * Router addon to allow meta.title to set the title
 */
router.beforeEach(async (to, from, next) => {

  if (!('tenant' in to.params)) {

    const params = returnParamWithTenant(to.params);
    next(params);
  }

  // Fix error url with two slash
  if (to.fullPath.includes('//')) {

    const path = to.fullPath.replace('//', '/');
    await router.push({
      path   : path,
      params : {
        ...to.params
      }
    });
    next();
    return;
  }

  const tenantPrefix = /\/tenant\/[A-Z0-9]+.*/;
  const tenantIdFromStore = store?.getters?.tenant?.id;
  if (!tenantIdFromStore && to.params.tenant) {
    await store.commit('EDIT_TENANT', { id : to.params.tenant });
  }

  const tenantId = store.getters.tenant.id;

  if (!to.path.match(tenantPrefix) && tenantId && to.name !== 'tenant-new') {

    // Replace '/tenant' in url if the user try to log without the tenant in url
    const path = to.fullPath.replace('/tenant', '');
    await router.push({
      path   : '/tenant/' + tenantId + path,
      params : {
        ...to.params,
        tenant : tenantId
      }
    });

    next();

    return;
  }

  // KEEP WINDOW SCROLL TOP WHEN FROM A LIST
  if (window && (from.name?.includes('list') || from.name === 'mandate-avenants')) {

    window.localStorage.scroll = JSON.stringify({ from : from.name, top : window.scrollY });
  }

  if (store.getters.isEditTab) {

    store.dispatch('tryToLeaveTabEdit', {
      to : {
        name   : to.name,
        params : to.params
      }
    });
    return;
  }

  // Check role
  if ('roles' in to.meta && 'layout' in to.meta) {

    if (to.meta.layout !== 'default' && !RBAC.can(store.getters.user.roles, to.meta.roles)) {
      next({ path : '/' });
      store.dispatch('changeLayout', 'default');
      return;
    }
  }

  // We inform the store on route change
  const layout = to.meta.layout !== undefined ? to.meta.layout : 'simple';

  if (store.getters.layout !== layout || (store.getters.menu_routes[layout].length === 0)) {

    store.dispatch('generateMenu', { user : store.getters.user, layout : layout });
  }

  store.dispatch('checkLogout');

  if (!to.meta.title) {

    if (to.meta.title !== false) {
      document.title = Config.title;
    }

    next();
    return;
  }

  document.title = typeof to.meta.title === 'function' ? to.meta.title(to) : i18n.t(to.meta.title);

  next();

});

router.afterEach((to) => {

  store.dispatch('changeLayout', to.meta.layout);

  if (!Vue.prototype.$posthog) {
    return;
  }

  setTimeout(() => {

    Vue.prototype.$posthog.capture('$pageview', { $current_url : to.fullPath });
  }, 0);
});

function returnParamWithTenant(params) {

  const tenantId = store.getters.tenant.id;
  if (tenantId) {

    return { ...params, tenant : tenantId };
  }

  return params;
}

export default router;
