<template>
  <div id="app">
    <Alert />
    <VersionAlert />
    <Transition name="fade">
      <Component :is="layout" />
    </Transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { computed }   from 'vue';

export default {
  name       : 'App',
  components : {
    'default'    : () => import('@/views/layouts/Default/index.vue'),
    'admin'      : () => import('@/views/layouts/Admin/index.vue'),
    'simple'     : () => import('@/views/layouts/Simple/index.vue'),
    Alert        : () => import('@/components/Views/Alert.vue'),
    VersionAlert : () => import('@/components/Views/VersionAlert.vue')
  },
  provide() {

    return {

      container : computed(() => document.getElementById('app'))
    };
  },
  computed : {
    ...mapGetters(['layout'])
  }
};
</script>
