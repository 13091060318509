import * as Types         from '../mutationTypes';
import ObjectManipulation from '@/utils/object';
import HestiaApi          from '@/api';

// initial state
const state = {
  clients       : {
    results    : [],
    pagination : {
      perPage : 10,
      page    : 1,
      total   : 0
    }
  },
  currentClient : {}

};

// getters
const getters = {
  commercialisationClients : state => state.clients
};

// actions
const actions = {
  async getCommercialisationClients({ commit }, { tenantId, query }) {

    const clients = await HestiaApi.Commercialisation.clientRegistration.list(tenantId, { searchParams : query }).json();
    commit(Types.RECEIVE_COMMERCIALISATION_CLIENTS, clients);
  },
  async addCommercialisationClient({ commit }, { tenantId, client, program }) {

    const response = await HestiaApi.Commercialisation.clientRegistration.create(tenantId, program, client).json();
    commit(Types.ADD_COMMERCIALISATION_CLIENT, response);
    return response;
  },
  async editCommercialisationClient({ commit }, { tenantId, programId, clientId, client }) {

    const editedClient = await HestiaApi.Commercialisation.clientRegistration.update(tenantId, programId, clientId, client).json();

    commit(Types.EDIT_COMMERCIALISATION_CLIENT, editedClient);

    return editedClient;
  },
  async removeCommercialisationClient({ commit }, { tenantId, clientId }) {

    await HestiaApi.Client.client.delete(tenantId, clientId);
    commit(Types.REMOVE_COMMERCIALISATION_CLIENT, clientId);
  }
};

// mutations
const mutations = {
  [Types.RECEIVE_COMMERCIALISATION_CLIENTS](state, clients) {

    state.clients = clients;
  },

  [Types.ADD_COMMERCIALISATION_CLIENT](state, client) {

    state.clients.results.push(client);
  },
  [Types.EDIT_COMMERCIALISATION_CLIENT](state, client) {

    client = {
      ...client,
      searches             : state.currentClient.searches,
      selectedSearchClient : state.currentClient.selectedSearchClient,
      emails               : state.currentClient.emails,
      phones               : state.currentClient.phones
    };

    state.currentClient = client;
    const index = state.clients.results.findIndex((cli) => cli.id === client.id);

    state.clients.results.splice(index, 1, client);
    state.clients = ObjectManipulation.clone(state.clients); // else deep objects are not updated, if it's not a deep object use splice instead ;)
  },
  [Types.REMOVE_COMMERCIALISATION_CLIENT](state, clientId) {

    state.clients.results.splice(clientId, 1);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
