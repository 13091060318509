import { Hephaestus } from '@pixul/hephaestus';
import '@pixul/hephaestus/dist/style.css';

import { Aglaea, Formatter } from '@pixul/aglaea';
import '@pixul/aglaea/dist/style.css';
import '/node_modules/@pixul/components/dist/components.css';
import 'normalize.css/normalize.css';
import '@/styles/element_custom.scss';
import '@pixul/element-ui/lib/theme-chalk/display.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'vue-my-photos/dist/lightbox.css';

import HestiaApi from '@/api';
import './utils/progress'; // Progress set up
import './utils/error'; // Error log
import 'vue-awesome/icons';
import './styles/index.scss';
import './styles/element.scss';
import './styles/element-card.scss';
import './styles/message.scss';
import './styles/dialog.scss';
import './styles/mixin.scss';
import './styles/transition.scss';

import * as Countries                       from 'i18n-iso-countries';
import * as Sentry                          from '@sentry/vue';
import { StripePlugin }                     from '@vue-stripe/vue-stripe';
import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor';

import ActionMixin             from '@/mixins/ActionMixin';
import App                     from './App.vue';
import AsyncComputed           from 'vue-async-computed';
import Config                  from '@/config';
import ContentTranslationMixin from '@/mixins/ContentTranslationMixin';
import Element                 from '@pixul/element-ui';
import FlagIcon                from 'vue-flag-icon';
import FormatMixin             from '@/mixins/FormatMixin';
import Price                   from '@/mixins/Price';
import Fragment                from 'vue-fragment';
import GlobalFunctionPlugin    from '@/plugins/GlobalFunctionPlugin';
import Icon                    from 'vue-awesome/components/Icon';
import LightTimeline           from 'vue-light-timeline';
import Moment                  from 'moment';
import RouteMixin              from '@/mixins/RouteMixin';
import Vue                     from 'vue';
import Vue2Filters             from 'vue2-filters'; // Some extra filter
import VueClipboard            from 'vue-clipboard2';
import VueMoment               from 'vue-moment';
import VueTelInput             from 'vue-tel-input';
import i18n                    from './lang'; // Internationalization
import router                  from './router';
import store                   from './store'; // Store
import Posthog                 from '@/plugins/Posthog';
import './registerServiceWorker';
import Components              from '@pixul/components';
import * as VueGoogleMaps      from 'gmap-vue';
import frLocale                from 'i18n-iso-countries/langs/fr.json';
import enLocale                from 'i18n-iso-countries/langs/en.json';

import { browserTracingIntegration, extraErrorDataIntegration } from '@sentry/vue';

Object.keys(Components).forEach((component) => {

  Vue.use(Components[component].install);
});

Vue.use(VueGoogleMaps, {
  load : {
    key       : Config.googleApiKey,
    libraries : 'places'
  }
});

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

// Add Sentry

Sentry.init({
  Vue,
  dsn              : Config.sentryDsn,
  environment      : Config.environment,
  release          : `hestia@${ Config.version }`,
  integrations     : [
    extraErrorDataIntegration({ depth : 7 }),
    browserTracingIntegration({
      router
    })
  ],
  normalizeDepth   : 8,
  tracingOptions   : {
    trackComponents : true
  },
  tracesSampleRate : 0.1,
  ignoreErrors     : [
    'ResizeObserver loop limit exceeded',
    'NavigationDuplicated',
    'Network Error',
    'Failed to fetch',
    'Request timed out',
    /Navigation cancelled from.*/,
    /Unable to preload CSS*.*/,
    /^Avoided redundant navigation to current location.*$/,
    'Request failed with status code 401',
    'Request failed with status code 404',
    'SecurityError: The operation is insecure.',
    `Failed to update a ServiceWorker for scope ('https://app.hestia.leggett.fr/') with script ('https://app.hestia.leggett.fr/service-worker.js'): An unknown error occurred when fetching the script.`,
    'Script URL https://app.hestia.leggett.fr/service-worker.js fetch resulted in error: The Internet connection appears to be offline.',
    /^Redirected when going from ".*. a navigation guard.$/,
    /.+net::ERR_BLOCKED_BY_CLIENT/,
    /.+De netwerkverbinding is verbroken./
  ]
});

if (Config.environment !== 'development') {
  Vue.use(Posthog, { token : Config.postHogToken, apiHost : Config.postHogApiHost });
} else {

  //On mock posthog en dev pour avoir accès a tout et ne pas avoir d'erreurs
  Vue.prototype.$posthog = {
    isFeatureEnabled() {

      return true;
    },

    capture() {}
  };
}

Countries.registerLocale(frLocale);
Countries.registerLocale(enLocale);

Vue.config.productionTip = false;

// Import custom filters
const filters = import.meta.glob('./filters/**/*.js', { eager : true });

Object.keys(filters).forEach((key => {

  Vue.filter(key.match(/\/(\w+).js/)[1], filters[key].default);
}));

Vue.use(LightTimeline);
Vue.use(Vue2Filters);
Vue.use(VueMoment, { moment : Moment });
Vue.use(Fragment.Plugin);

Vue.component('Icon', Icon);
Vue.use(GlobalFunctionPlugin);
Vue.use(FlagIcon);
Vue.mixin(ActionMixin);
Vue.mixin(ContentTranslationMixin);
Vue.mixin(FormatMixin);
Vue.mixin(Price);
Vue.mixin(RouteMixin);

Vue.use(AsyncComputed);
Vue.use(VueTelInput);

Vue.use(VueClipboard);

Vue.use(Components);

Vue.use(Element, {
  size : 'medium', // default element-ui size
  i18n : (key, value) => i18n.t(key, value)
});

Vue.use(Hephaestus);
Vue.use(Aglaea);
const formatter = new Formatter(i18n);
Vue.use(formatter);
Vue.use(StripePlugin, {
  pk            : Config.STRIPE_PUBLISHABLE_KEY,
  stripeAccount : import.meta.env.VITE_APP_STRIPE_ACCOUNT,
  apiVersion    : import.meta.env.VITE_APP_STRIPE_API_VERSION,
  locale        : import.meta.env.VITE_APP_STRIPE_LOCALE
});

Vue.use(VueMonacoEditorPlugin, {
  paths : {
        // The recommended CDN config
    vs : 'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs'
  }
});

Vue.prototype.$hestia = HestiaApi;

/* eslint-disable no-new */
new Vue({
  el         : '#app',
  router,
  i18n,
  store,
  components : { App },
  template   : '<App/>'
});
