import * as Types from '../mutationTypes';

import HestiaApi from '@/api';

// initial state
const state = {
  bookmarks         : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  },
  selectedBookmarks : {
    client   : {},
    company  : {},
    mandate  : {},
    offer    : {},
    property : {},
    request  : {},
    issue    : {}
  }
};

// getters
const getters = {
  bookmarks         : state => state.bookmarks,
  selectedBookmarks : state => state.selectedBookmarks
};

// actions
const actions = {
  async addBookmark({ commit }, { tenantId, bookmark }) {

    const response = await HestiaApi.Bookmark.bookmark.create(tenantId, bookmark).json();
    commit(Types.ADD_BOOKMARK, response);
    return response;
  },
  async getBookmarks({ commit }, { tenantId, userId }) {

    const response = await HestiaApi.Bookmark.bookmark.list(tenantId, { searchParams : { userId } }).json();
    commit(Types.RECEIVE_BOOKMARKS, response);
  },
  async editBookmark({ commit }, { tenantId, bookmarkId, bookmark }) {

    const response = await HestiaApi.Bookmark.bookmark.update(tenantId, bookmarkId, bookmark).json();
    commit(Types.EDIT_BOOKMARK, response);
  },
  async removeBookmark({ commit }, { tenantId, bookmarkId }) {

    await HestiaApi.Bookmark.bookmark.delete(tenantId, bookmarkId);
    commit(Types.REMOVE_BOOKMARK, bookmarkId);
  },
  async selectBookmark({ commit }, { tenantId, id, page, pagination }) {

    const response = await HestiaApi.Bookmark.bookmark.get(tenantId, id).json();

    commit(Types.SELECT_BOOKMARK, { bookmark : response, page });

    const query = response.data;
    if (pagination) {

      query.pagination = pagination;
    }

    commit(Types.UPDATE_QUERY, { model : page, query, page });
  },
  async unselectBookmark({ commit }, { page }) {

    commit(Types.UNSELECT_BOOKMARK, { page });
  },

  async clearBookmarks({ commit }) {

    commit(Types.CLEAR_BOOKMARKS);
  }
};

// mutations
const mutations = {
  [Types.RECEIVE_BOOKMARKS](state, bookmarks) {

    state.bookmarks.results = bookmarks;
  },

  [Types.CLEAR_BOOKMARKS](state) {

    state.bookmarks.results = [];
    state.selectedBookmarks = {
      client   : {},
      company  : {},
      mandate  : {},
      offer    : {},
      property : {},
      request  : {},
      issue    : {}
    };
  },
  [Types.ADD_BOOKMARK](state, bookmark) {

    state.bookmarks.results.push(bookmark);
  },
  [Types.EDIT_BOOKMARK](state, bookmark) {

    const index = state.bookmarks.results.findIndex((bk) => bk.id === bookmark.id);
    state.bookmarks.results.splice(index, 1, bookmark);

    for (const bookmarkResult of state.bookmarks.results) {

      if (bookmarkResult !== bookmark && bookmarkResult.metadata.model === bookmark.metadata.model) {
        bookmarkResult.default = false;
      }
    }
  },
  [Types.REMOVE_BOOKMARK](state, bookmarkId) {

    const index = state.bookmarks.results.findIndex((bk) => bk.id === bookmarkId);
    state.bookmarks.results.splice(index, 1);
  },
  [Types.SELECT_BOOKMARK](state, { bookmark, page }) {

    //Reset page info in case page was stored in database
    if (bookmark.data && bookmark.data.pagination) {
      bookmark.data.pagination.page = 1;
    }

    state.selectedBookmarks = { ...state.selectedBookmarks, [page] : bookmark };
  },
  [Types.UNSELECT_BOOKMARK](state, { page }) {

    state.selectedBookmarks[page] = {};
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
