import * as Types from '../mutationTypes';
import HestiaApi  from '@/api';

// initial state
const state = {
  bookings : {
    results    : [],
    pagination : {
      perPage : 10,
      page    : 1,
      total   : 0
    }
  }
};

// getters
const getters = {
  bookings : state => state.bookings
};

// actions
const actions = {

  // BOOKINGS
  async getBookings({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Commercialisation.booking.list(tenantId, { searchParams : query }).json();
    commit(Types.RECEIVE_BOOKINGS, response);
  }
};

// mutations
const mutations = {
  [Types.RECEIVE_BOOKINGS](state, bookings) {

    state.bookings = bookings;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
