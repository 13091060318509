import * as Types from '../mutationTypes';
import HestiaApi  from '@/api';

import bodybuilder from 'bodybuilder';

// initial state
const state = {
  appointments       : {
    results    : [],
    pagination : {
      perPage : 10,
      page    : 1,
      total   : 0
    }
  },
  currentAppointment : {},
  sendAppointments   : []
};

// getters
const getters = {
  appointments       : state => state.appointments,
  currentAppointment : state => state.currentAppointment,
  sendAppointments   : state => state.sendAppointments
};

// actions
const actions = {
  async addAppointment({ commit }, { tenantId, appointment }) {

    const response = await HestiaApi.Appointment.appointment.create(tenantId, appointment).json();
    commit(Types.ADD_APPOINTMENT, response);
    return response;
  },
  async getAppointments({ commit }, { tenantId, query }) {

    const appointments = await HestiaApi.Appointment.appointment.list(tenantId, { searchParams : query }).json();

    appointments.results = await Promise.all(appointments.results.map(async a => {

      a.clients = await Promise.all(a.clients.map(async (c) => {

        const bodyThread = bodybuilder();

        bodyThread.sort('createdAt', 'desc');

        bodyThread.query('match', 'type', 'MESSAGE');

        bodyThread.query('match', 'metadata.identifier.id', a.id);
        bodyThread.query('match', 'metadata.identifier.id', c);

        const threads = await HestiaApi.Thread.thread.search(tenantId, bodyThread.build()).json();

        return {
          clientId : c,
          threads  : threads.hits.hits.map(t => t._source)
        };
      }));

      return a;
    }));

    commit(Types.RECEIVE_APPOINTMENTS, appointments);
  },
  async editAppointment({ commit }, { tenantId, appointmentId, appointment }) {

    const appointmentData = await HestiaApi.Appointment.appointment.update(tenantId, appointmentId, appointment).json();

    appointmentData.clients = await Promise.all(appointmentData.clients.map(async c => {

      const bodyThread = bodybuilder();

      bodyThread.sort('createdAt', 'desc');

      bodyThread.query('match', 'type', 'MESSAGE');

      bodyThread.query('match', 'metadata.identifier.id', appointmentId);
      bodyThread.query('match', 'metadata.identifier.id', c);

      const threads = await HestiaApi.Thread.thread.search(tenantId, bodyThread.build()).json();

      return {
        clientId : c,
        threads  : threads.hits.hits.map(t => t._source)
      };
    }));

    appointmentData.clientsInfos = await Promise.all(appointmentData.clients.map((c) => {

      return HestiaApi.Client.client.get(tenantId, c.clientId).json();
    }));

    appointmentData.propertyInfos = await HestiaApi.Property.property.get(tenantId, appointmentData.property).json();

    commit(Types.EDIT_APPOINTMENT, appointmentData);

    return appointmentData;
  },
  editAppointmentStatus({ commit }, { appointment }) {

    commit(Types.EDIT_APPOINTMENT_STATUS, { appointment });
  },
  async editAppointmentClient({ commit }, { tenantId, appointmentId, clients }) {

    const clientsIds = clients.map(c => c.clientId);

    await HestiaApi.Appointment.appointment.update(tenantId, appointmentId, { clients : clientsIds });
    commit(Types.EDIT_APPOINTMENT_CLIENT, clients);
  },
  async removeAppointment({ commit }, { tenantId, appointmentId }) {

    await HestiaApi.Appointment.appointment.delete(tenantId, appointmentId);
    commit(Types.REMOVE_APPOINTMENT, appointmentId);
  },
  async selectAppointment({ commit }, { tenantId, id }) {

    const appointment = await HestiaApi.Appointment.appointment.get(tenantId, id).json();

    appointment.clients = await Promise.all(appointment.clients.map(async (c) => {

      const bodyThread = bodybuilder();

      bodyThread.sort('createdAt', 'desc');

      bodyThread.query('match', 'type', 'MESSAGE');

      bodyThread.query('match', 'metadata.identifier.id', id);
      bodyThread.query('match', 'metadata.identifier.id', c);

      const threads = await HestiaApi.Thread.thread.search(tenantId, bodyThread.build()).json();

      return {
        clientId : c,
        threads  : threads.hits.hits.map(t => t._source)
      };
    }));

    appointment.clientsInfos = await Promise.all(appointment.clients.map((c) => {

      return HestiaApi.Client.client.get(tenantId, c.clientId).json();
    }));

    appointment.propertyInfos = await HestiaApi.Property.property.get(tenantId, appointment.property).json();

    commit(Types.SELECT_APPOINTMENT, appointment);
  },
  addThreadToAppointment({ commit }, { appointmentId, clientId, thread }) {

    commit(Types.ADD_THREAD_APPOINTMENT, { appointmentId, clientId, thread });
  },
  async getSendAppointments({ commit }, tenantId) {

    const response = await HestiaApi.Appointment.template.list(tenantId).json();
    commit(Types.RECEIVE_SEND_APPOINTMENT, response);
  },
  async addSendAppointment({ commit }, { tenantId, language, sendAppointment }) {

    const response = await HestiaApi.Appointment.template.put(tenantId, language, sendAppointment).json();
    commit(Types.ADD_SEND_APPOINTMENT, response);
  },
  async editSendAppointment({ commit }, { tenantId, language, sendAppointment }) {

    const response = await HestiaApi.Appointment.template.put(tenantId, language, sendAppointment).json();
    commit(Types.EDIT_SEND_APPOINTMENT, response);
  },
  async deleteSendAppointment({ commit }, { tenantId, language }) {

    await HestiaApi.Appointment.template.delete(tenantId, language);
    commit(Types.REMOVE_SEND_APPOINTMENT, language);
  },
  async addStatusAppointment({ commit }, { tenantId, appointmentId, appointment }) {

    const response = await HestiaApi.Appointment.appointment.status(tenantId, appointmentId, appointment).json();
    commit(Types.EDIT_STATUS_APPOINTMENT, response);
  },
  // ACL
  async editAppointmentOwner({ commit }, { tenantId, id, owner }) {

    await HestiaApi.Appointment.appointment.update(tenantId, id, { owner : owner.userId }).json();

    commit(Types.EDIT_APPOINTMENT_OWNER, owner);

  }
};

// mutations
const mutations = {
  [Types.RECEIVE_APPOINTMENTS](state, appointments) {

    state.appointments = appointments;
  },
  [Types.ADD_APPOINTMENT](state, appointment) {

    state.appointments.results.push(appointment);
  },
  [Types.EDIT_APPOINTMENT](state, appointment) {

    state.currentAppointment = appointment;
  },
  [Types.EDIT_APPOINTMENT_STATUS](state, { appointment }) {

    const indexApp = state.appointments.results.findIndex(a => a.id === appointment.id);

    if (indexApp !== -1) {

      state.appointments.results[indexApp].statuses = appointment.statuses;
    }
  },
  [Types.EDIT_APPOINTMENT_CLIENT](state, clients) {

    state.currentAppointment.clients = clients;
  },
  [Types.REMOVE_APPOINTMENT](state, appointmentId) {

    const index = state.appointments.results.findIndex((a) => a.id === appointmentId);
    state.appointments.results.splice(index, 1);
  },

  [Types.SELECT_APPOINTMENT](state, appointment) {

    state.currentAppointment = appointment;
    //state.selectedAppointments = { ...state.selectedAppointments }
  },
  [Types.ADD_THREAD_APPOINTMENT](state, { clientId, thread }) {

    const indexClient = state.currentAppointment.clients.findIndex(c => c.clientId === clientId);
    state.currentAppointment.clients[indexClient].threads.push(thread);
  },

  [Types.RECEIVE_SEND_APPOINTMENT](state, sendAppointments) {

    state.sendAppointments = sendAppointments;
  },
  [Types.ADD_SEND_APPOINTMENT](state, sendAppointment) {

    state.sendAppointments.push(sendAppointment);
  },
  [Types.EDIT_SEND_APPOINTMENT](state, sendAppointment) {

    const index = state.sendAppointments.findIndex((a) => a.language === sendAppointment.language);
    state.sendAppointments.splice(index, 1, sendAppointment);
  },
  [Types.REMOVE_SEND_APPOINTMENT](state, language) {

    const index = state.sendAppointments.findIndex((a) => a.language === language);
    state.sendAppointments.splice(index, 1);
  },
  [Types.EDIT_STATUS_APPOINTMENT](state, appointment) {

    if (state.currentAppointment && state.currentAppointment.statuses) {

      state.currentAppointment.statuses.push(appointment);
    }
  },
  [Types.EDIT_APPOINTMENT_OWNER](state, owner) {

    state.currentAppointment.owner = owner.userId;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
