import * as Types  from '../mutationTypes';
import HestiaApi   from '@/api';
import ObjectUtils from '@/utils/object';

// initial state
const state = {
  properties                  : {
    results    : [],
    pagination : {
      perPage : 10,
      page    : 1,
      total   : 0
    }
  },
  properties_map              : {
    results : []
  },
  property_types              : [],
  currentProperty             : {},
  currentPropertyCounts       : {
    'matches'      : null,
    'mandates'     : null,
    'feeds'        : null,
    'appointments' : null,
    'documents'    : null,
    'images'       : null,
    'medias'       : null,
    'issues'       : null,
    'offers'       : null,
    'requests'     : null,
    'publications' : null
  },
  currentPropertyMatches      : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  },
  currentPropertyAppointments : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  },
  currentPropertyIssues       : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  },
  currentPropertyOverview     : {},
  propertyOffers              : {
    results    : [],
    pagination : {
      perPage : 10,
      page    : 1,
      total   : 0
    }
  },
  property_features           : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  },
  property_categories         : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  },
  propertyDocumentTypes       : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  },
  property_primary_types      : [],
  property_secondary_types    : [],
  propertyPublications        : [],
  currentPropertyPublications : [],
  propertyBrochures           : [],
  currentPropertyBrochures    : [],
  currentPropertyFeeds        : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  },
  currentPropertyRequests     : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  },
  properties_sources          : [],
  properties_brochures        : [],
  propertyMediaTab            : {},
  currentClientUpdateFrom     : { from : [], key : 'noFilter' }
};

// getters
const getters = {
  properties                  : state => state.properties,
  properties_map              : state => state.properties_map,
  property_features           : state => state.property_features,
  property_categories         : state => state.property_categories,
  property_types              : state => state.property_types,
  property_primary_types      : state => state.property_primary_types,
  property_secondary_types    : state => state.property_secondary_types,
  propertyDocumentTypes       : state => state.propertyDocumentTypes,
  currentProperty             : state => state.currentProperty,
  currentPropertyCounts       : state => state.currentPropertyCounts,
  currentPropertyMatches      : state => state.currentPropertyMatches,
  currentPropertyAppointments : state => state.currentPropertyAppointments,
  currentPropertyIssues       : state => state.currentPropertyIssues,
  currentPropertyOverview     : state => state.currentPropertyOverview,
  propertyOffers              : state => state.propertyOffers,
  currentPropertyFeeds        : state => state.currentPropertyFeeds,
  currentPropertyRequests     : state => state.currentPropertyRequests,
  currentPropertyPublications : state => state.currentPropertyPublications,
  currentPropertyBrochures    : state => state.currentPropertyBrochures,
  properties_sources          : state => state.properties_sources,
  properties_brochures        : state => state.properties_brochures,
  propertyMediaTab            : state => state.propertyMediaTab,
  currentClientUpdateFrom     : state => state.currentClientUpdateFrom
};

// actions
const actions = {
  // Properties
  async getProperties({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Property.property.search(tenantId, query).json();

    const properties = {
      results      : response.hits.hits.map((m) => m._source),
      pagination   : {
        perPage : query.size,
        page    : ((query.from / query.size) + 1),
        total   : response.hits.total.value
      },
      aggregations : response.aggregations
    };

    commit(Types.RECEIVE_PROPERTIES, properties);
  },

  setProperties({ commit }, properties) {

    commit(Types.RECEIVE_PROPERTIES, properties);
  },

  // Properties
  async getPropertiesMap({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Property.property.search(tenantId, query).json();

    const properties = {
      total    : response.hits.total.value,
      results  : response.aggregations.grid.buckets,
      viewport : response.aggregations.viewport
    };
    commit(Types.RECEIVE_PROPERTIES_MAP, properties);
  },
  async addProperty(_, { tenantId, property }) {

    if (property.address && property.address.coordinates && (!property.address.coordinates.lat || !property.address.coordinates.lon)) {

      delete property.address.coordinates;
    }

    return await HestiaApi.Property.property.create(tenantId, property).json();

  },
  async editProperty({ commit }, { tenantId, id, property : { poa, servicesPlus, ...property } }) {

    if (property.address && property.address.coordinates && (!property.address.coordinates.lat || !property.address.coordinates.lon)) {

      delete property.address.coordinates;
    }

    if (poa !== undefined && poa !== state.currentProperty.poa) {

      if (poa) {

        await HestiaApi.Property.property.enablePoa(tenantId, id);
      } else {

        await HestiaApi.Property.property.disablePoa(tenantId, id);
      }
    }

    if (servicesPlus?.rhinov !== undefined) {

      await HestiaApi.Property.property.updateRhinov(tenantId, id, servicesPlus.rhinov);
    }

    const response = await HestiaApi.Property.property.update(tenantId, id, property).json();

    commit(Types.EDIT_PROPERTY, response);
  },
  async removeProperty({ commit }, { tenantId, propertyId }) {

    await HestiaApi.Property.property.delete(tenantId, propertyId);
    commit(Types.REMOVE_PROPERTY, propertyId);
  },

  // Properties - image
  async addPropertyImage({ commit }, { tenantId, id, image }) {

    const response = await HestiaApi.Property.image.create(tenantId, id, image).json();

    commit(Types.PROPERTY_ADD_IMAGE, response);
  },
  async editPropertyImage({ commit }, { tenantId, propertyId, id, image }) {

    const response = await HestiaApi.Property.image.update(tenantId, propertyId, id, image).json();
    commit(Types.EDIT_PROPERTY_IMAGE, response);
  },
  async removePropertyImage({ commit }, { tenantId, propertyId, imageId }) {

    await HestiaApi.Property.image.delete(tenantId, propertyId, imageId);

    commit(Types.REMOVE_PROPERTY_IMAGE, imageId);
  },
  // Properties - feature
  async addPropertyFeature({ commit }, { tenantId, feature, currentCategoryId }) {

    const newFeature = await HestiaApi.Property.feature.add(tenantId, feature).json();

    if (feature.categoryId === currentCategoryId) {

      commit(Types.ADD_PROPERTY_FEATURE, newFeature);
    }

    return newFeature;
  },
  async getPropertyFeatures({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Property.feature.list(tenantId, { searchParams : query }).json();
    commit(Types.RECEIVE_PROPERTY_FEATURES, response);
  },
  async editPropertyFeature({ commit }, { tenantId, featureId, feature }) {

    const response = await HestiaApi.Property.feature.update(tenantId, featureId, feature).json();
    commit(Types.EDIT_PROPERTY_FEATURE, response);
  },
  async editPropertyFeatureOrder({ commit }, { tenantId, featureId, newOrder, oldOrder }) {

    const response = await HestiaApi.Property.feature.update(tenantId, featureId, { order : newOrder }).json();
    commit(Types.EDIT_PROPERTY_FEATURE_ORDER, { feature : response, newOrder : newOrder, oldOrder : oldOrder });
  },
  async removePropertyFeature({ commit }, { tenantId, featureId }) {

    await HestiaApi.Property.feature.delete(tenantId, featureId);
    commit(Types.REMOVE_PROPERTY_FEATURE, featureId);
  },
  // Properties - Categories
  async addPropertyCategory({ commit }, { tenantId, category }) {

    const response = await HestiaApi.Property.category.add(tenantId, category).json();
    commit(Types.ADD_PROPERTY_CATEGORY, response);
    return response;
  },
  async getPropertyCategories({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Property.category.list(tenantId, { searchParams : query }).json();
    commit(Types.RECEIVE_PROPERTY_CATEGORIES, response);
  },
  async editPropertyCategory({ commit }, { tenantId, categoryId, category }) {

    const response = await HestiaApi.Property.category.update(tenantId, categoryId, category).json();
    commit(Types.EDIT_PROPERTY_CATEGORY, response);
  },
  async editPropertyCategoryOrder({ commit }, { tenantId, categoryId, newOrder, oldOrder }) {

    const response = await HestiaApi.Property.category.update(tenantId, categoryId, { order : newOrder }).json();
    commit(Types.EDIT_PROPERTY_CATEGORY_ORDER, { category : response, newOrder : newOrder, oldOrder : oldOrder });
  },
  async removePropertyCategory({ commit }, { tenantId, categoryId }) {

    await HestiaApi.Property.category.delete(tenantId, categoryId);
    commit(Types.REMOVE_PROPERTY_CATEGORY, categoryId);
  },
  // Properties - type
  async getPropertyTypes({ commit }, { tenantId, query }) {

    const types = await HestiaApi.Property.type.list(tenantId, { searchParams : query }).json();
    commit(Types.RECEIVE_PROPERTY_TYPES, { types });
  },

  //
  async getPrimaryPropertyTypes({ commit }, { tenantId, query }) {

    const types = await HestiaApi.Property.type.list(tenantId, { searchParams : query }).json();
    commit(Types.RECEIVE_PROPERTY_TYPES, { types, arrayName : 'property_primary_types' });
  },
  async getSecondaryPropertyTypes({ commit }, { tenantId, query }) {

    const types = await HestiaApi.Property.type.list(tenantId, { searchParams : query }).json();
    commit(Types.RECEIVE_PROPERTY_TYPES, { types, arrayName : 'property_secondary_types' });
  },

  //
  async addPropertyType({ commit }, { tenantId, type, arrayName }) {

    const newType = await HestiaApi.Property.type.create(tenantId, type).json();
    commit(Types.ADD_PROPERTY_TYPE, { type : newType, arrayName });
    return newType;
  },
  async editPropertyType({ commit }, { tenantId, typeId, type, arrayName }) {

    const response = await HestiaApi.Property.type.update(tenantId, typeId, type).json();
    commit(Types.EDIT_PROPERTY_TYPE, { type : response, arrayName });
  },
  async editPropertyTypeOrder({ commit }, { tenantId, typeId, newOrder, oldOrder, arrayName }) {

    const response = await HestiaApi.Property.type.update(tenantId, typeId, { order : newOrder }).json();
    commit(Types.EDIT_PROPERTY_TYPE_ORDER, { type : response, newOrder, oldOrder, arrayName });
  },
  async removePropertyType({ commit }, { tenantId, typeId, arrayName }) {

    await HestiaApi.Property.type.delete(tenantId, typeId);
    commit(Types.REMOVE_PROPERTY_TYPE, { typeId, arrayName : arrayName });
  },

  async getPropertyCounts({ commit }, { tenantId, property }) {

    const id = property.id;
    try {
      const { hits : { total : { value : issues } } } = await HestiaApi.Thread.thread.search(tenantId, {
        'query' : {
          'bool' : {
            'must' : [
              { 'match' : { 'type' : 'ISSUE' } },
              {
                'bool' : {
                  'should' : [
                    {
                      'nested' : {
                        'path'  : 'metadata',
                        'query' : { 'bool' : { 'should' : [{ 'match' : { 'metadata.identifier.id' : id } }] } }
                      }
                    }, {
                      'nested' : {
                        'path'  : 'additionalMetadata',
                        'query' : { 'bool' : { 'should' : [{ 'match' : { 'additionalMetadata.identifier.id' : id } }] } }
                      }
                    }
                  ]
                }
              }
            ]
          }
        }
      }).json();
      commit(Types.UPDATE_PROPERTY_COUNTS, {
        'property' : property,
        'counts'   : {
          'issues' : issues
        }
      });
    } catch (e) { /* empty */
    }

    try {
      const { count : feeds } = await HestiaApi.Feed.state.countStates(tenantId, id).json();

      commit(Types.UPDATE_PROPERTY_COUNTS, {
        'property' : property,
        'counts'   : {
          'feeds' : feeds
        }
      });
    } catch (e) { /* empty */
    }

    try {
      const { pagination : { total : requests } } = await HestiaApi.Lead.request.list(tenantId, { searchParams : { 'propertyId:in' : id } })
        .json();

      commit(Types.UPDATE_PROPERTY_COUNTS, {
        'property' : property,
        'counts'   : {
          'requests' : requests
        }
      });
    } catch (e) { /* empty */
    }

    try {
      const { pagination : { total : appointments } } = await HestiaApi.Appointment.appointment.list(tenantId, { searchParams : { 'property:eq' : id } })
        .json();
      commit(Types.UPDATE_PROPERTY_COUNTS, {
        'property' : property,
        'counts'   : {
          'appointments' : appointments
        }
      });
    } catch (e) { /* empty */
    }

    try {
      if (property.lotId) {

        const bookings = await HestiaApi.Commercialisation.booking.list(tenantId, {
          searchParams : { 'lotId:eq' : property.lotId }
        }).json();
        commit(Types.UPDATE_PROPERTY_COUNTS, {
          'property' : property,
          'counts'   : {
            'offers' : bookings.pagination.total
          }
        });
        commit(Types.RECEIVE_PROPERTY_BOOKINGS, bookings);
      } else {

        const offers = await HestiaApi.Property.offer.list(tenantId, { searchParams : { 'propertyId:eq' : id } })
          .json();
        commit(Types.UPDATE_PROPERTY_COUNTS, {
          'property' : property,
          'counts'   : {
            'offers' : offers.pagination.total
          }
        });
        commit(Types.RECEIVE_PROPERTY_OFFERS, offers);
      }
    } catch (e) { /* empty */
    }

    try {
      const { hits : { total : { value : matches } } } = await HestiaApi.Client.search.byProperty(tenantId, id).json();
      commit(Types.UPDATE_PROPERTY_COUNTS, {
        'property' : property,
        'counts'   : {
          'matchs' : matches
        }
      });
    } catch (e) { /* empty */
    }
  },
  async getPropertyOverview({ commit }, { tenantId, propertyId, options }) {

    const response = await HestiaApi.Feed.metric.get(tenantId, propertyId, { searchParams : options }).json();
    commit(Types.RECEIVE_PROPERTY_OVERVIEW, response);
  },

  async selectProperty({ commit }, { tenantId, id }) {

    commit(Types.INIT_PROPERTY_COUNTS);

    const property = await HestiaApi.Property.property.get(tenantId, id).json();

    property.mandates = await Promise.all(property.mandates.map((mandateId) => {

      return HestiaApi.Mandate.mandate.get(tenantId, mandateId).json();
    }));

    commit(Types.SELECT_PROPERTY, property);
  },

  // Property - Mandates
  async addPropertyMandate({ commit }, { tenantId, id, property, mandate }) {

    await HestiaApi.Property.property.update(tenantId, id, {
      mandates : [
        ...property.mandates.map(m => m.id),
        mandate.id
      ]
    });

    commit(Types.ADD_PROPERTY_MANDATE, mandate);
  },
  async removePropertyMandate({ commit }, { tenantId, id, property, mandateId }) {

    const mandateIds = property.mandates.map(m => m.id);

    const index = mandateIds.indexOf(mandateId);
    mandateIds.splice(index, 1);

    await HestiaApi.Property.property.update(tenantId, id, {
      mandates : mandateIds
    });

    commit(Types.REMOVE_PROPERTY_MANDATE, mandateId);
  },

  //Properties - Medium
  async addPropertyMedium({ commit }, { tenantId, propertyId, medium }) {

    const response = await HestiaApi.Property.medium.create(tenantId, propertyId, medium).json();
    commit(Types.ADD_PROPERTY_MEDIUM, response);
    return response;
  },
  async editPropertyMedium({ commit }, { tenantId, propertyId, id, medium }) {

    const response = await HestiaApi.Property.medium.update(tenantId, propertyId, id, medium).json();
    commit(Types.EDIT_PROPERTY_MEDIUM, response);
    return response;
  },
  async removePropertyMedium({ commit }, { tenantId, propertyId, mediumId }) {

    await HestiaApi.Property.medium.delete(tenantId, propertyId, mediumId);
    commit(Types.REMOVE_PROPERTY_MEDIUM, mediumId);
  },
  async addPropertyIssue({ commit }, { issue }) {

    commit(Types.ADD_PROPERTY_ISSUE, issue);
  },

  async getPropertyAppointments({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Appointment.appointment.list(tenantId, { searchParams : query }).json();
    commit(Types.RECEIVE_PROPERTY_APPOINTMENTS, response);
  },
  async getPropertyRequests({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Lead.request.list(tenantId, { searchParams : query }).json();
    commit(Types.RECEIVE_PROPERTY_REQUESTS, response);
  },
  async getPropertyIssues({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Thread.thread.search(tenantId, query).json();

    const issues = {
      results    : response.hits.hits.map((m) => m._source),
      pagination : {
        perPage : query.size,
        page    : ((query.from / query.size) + 1),
        total   : response.hits.total.value
      }
    };

    commit(Types.RECEIVE_PROPERTY_ISSUES, issues);
  },
  async getPropertyFeeds({ commit }, { tenantId, query }) {

    const propertyId = query['propertyId:eq'];
    query = ObjectUtils.omit(query, ['propertyId:eq']);

    const response = await HestiaApi.Feed.state.getStates(tenantId, propertyId, { searchParams : query }).json();
    commit(Types.RECEIVE_PROPERTY_FEEDS, response);
  },
  async blackListFeed({ commit }, { tenantId, propertyId, feedId, params }) {

    const response = await HestiaApi.Feed.state.putBlacklisted(tenantId, propertyId, feedId, params).json();
    commit(Types.BLACKLIST_PROPERTY_FEEDS, response);
  },
  async getPropertyMatches({ commit }, { tenantId, query }) {

    const propertyId = query['propertyId:eq'];
    query = ObjectUtils.omit(query, ['propertyId:eq', 'orderByDesc']);

    const response = await HestiaApi.Client.search.byProperty(tenantId, propertyId, { searchParams : query }).json();

    const searches = {
      results    : response.hits.hits.map((m) => m._source.search),
      pagination : {
        perPage : query.perPage,
        page    : query.page,
        total   : response.hits.total.value
      }
    };

    commit(Types.RECEIVE_PROPERTY_MATCHES, searches);
  },
  async editClientUpdatedFrom({ commit }, { updated_from }) {

    commit(Types.CURRENT_CLIENT_UPDATED_FROM, updated_from);
  },
  // ACL
  async editPropertyOwner({ commit }, { tenantId, id, owner, editCurrentProperty = true }) {

    const response = await HestiaApi.Property.property.editOwner(tenantId, id, owner).json();

    if (editCurrentProperty) {

      commit(Types.EDIT_PROPERTY, response);
    }
  },
  async addPropertyCollaborator({ commit }, { tenantId, id, collaborator, editCurrentProperty = true }) {

    const response = await HestiaApi.Property.property.addCollaborator(tenantId, id, collaborator).json();

    if (editCurrentProperty) {

      commit(Types.EDIT_PROPERTY, response);
    }
  },
  async addPropertyCoowner({ commit }, { tenantId, id, coowner, editCurrentProperty = true }) {

    const response = await HestiaApi.Property.property.addCoOwner(tenantId, id, coowner).json();

    if (editCurrentProperty) {

      commit(Types.EDIT_PROPERTY, response);
    }
  },
  async removePropertyCollaborator({ commit }, { tenantId, id, collaborator, editCurrentProperty = true }) {

    await HestiaApi.Property.property.removeCollaborator(tenantId, id, collaborator);

    if (editCurrentProperty) {

      commit(Types.REMOVE_PROPERTY_COLLABORATOR, collaborator);
    }
  },
  async removePropertyCoowner({ commit }, { tenantId, id, coowner, editCurrentProperty = true }) {

    await HestiaApi.Property.property.removeCoOwner(tenantId, id, coowner);

    if (editCurrentProperty) {

      commit(Types.REMOVE_PROPERTY_COOWNER, coowner);
    }
  },
  async getPropertyOffers({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Property.offer.list(tenantId, { searchParams : query }).json();

    commit(Types.RECEIVE_PROPERTY_OFFERS, response);
  },
  setPropertyOffers({ commit }, offers) {

    commit(Types.RECEIVE_PROPERTY_OFFERS, offers);
  },
  async getPropertyBookings({ commit }, { tenantId, lotId }) {

    const response = await HestiaApi.Commercialisation.booking.list(tenantId, {
      searchParams : { 'lotId:eq' : lotId }
    }).json();

    commit(Types.RECEIVE_PROPERTY_BOOKINGS, response);
  },
  async addOffer({ commit }, { tenantId, propertyId, offer }) {

    const response = await HestiaApi.Property.offer.create(tenantId, propertyId, offer).json();

    commit(Types.ADD_OFFER, response);
  },
  async editOffer({ commit }, { tenantId, propertyId, id, offer }) {

    const response = await HestiaApi.Property.offer.update(tenantId, propertyId, id, offer).json();

    commit(Types.EDIT_OFFER, response);
  },
  addPropertyAppointment({ commit }) {

    commit(Types.ADD_PROPERTY_APPOINTMENT);
  },
  addPropertyOffer({ commit }) {

    commit(Types.ADD_PROPERTY_OFFER);
  },
  async addPropertyDocument({ commit }, { tenantId, id, document }) {

    const newDocument = await HestiaApi.Property.document.add(tenantId, id, document).json();
    commit(Types.ADD_PROPERTY_DOCUMENT, newDocument);
  },
  async editPropertyDocument({ commit }, { tenantId, propertyId, id, document }) {

    const response = await HestiaApi.Property.document.update(tenantId, propertyId, id, document).json();
    commit(Types.EDIT_PROPERTY_DOCUMENT, response);
  },
  async removePropertyDocument({ commit }, { tenantId, propertyId, documentId, isSuperAdmin }) {

    await HestiaApi.Property.document.remove(tenantId, propertyId, documentId);
    commit(Types.DELETE_PROPERTY_DOCUMENT, { documentId, isSuperAdmin });
  },
  async undeletePropertyDocument({ commit }, { tenantId, propertyId, documentId }) {

    await HestiaApi.Property.document.undelete(tenantId, propertyId, documentId);
    commit(Types.UNDELETE_PROPERTY_DOCUMENT, documentId);
  },
  async recountPropertyFeed({ commit }, { tenantId, propertyId }) {

    const feed = await HestiaApi.Feed.state.countStates(tenantId, propertyId).json();
    commit(Types.RECOUNT_PROPERTY_FEED, feed);
  },
  // SOURCE
  async addPropertySource({ commit }, { tenantId, source }) {

    const response = await HestiaApi.Property.source.create(tenantId, source).json();
    commit(Types.ADD_PROPERTY_SOURCE, response);
    return response;
  },
  async getPropertySources({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Property.source.list(tenantId, { searchParams : query }).json();
    commit(Types.RECEIVE_PROPERTY_SOURCES, response);
  },
  async removePropertySource({ commit }, { tenantId, source }) {

    await HestiaApi.Property.source.delete(tenantId, source.id).json();
    commit(Types.REMOVE_PROPERTY_SOURCE, source);
  },
  async editPropertySource({ commit }, { tenantId, id, source }) {

    const response = await HestiaApi.Property.source.update(tenantId, id, source).json();
    commit(Types.EDIT_PROPERTY_SOURCE, response);
  },
  // BROCHURE
  async getBrochures({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Property.brochure.list(tenantId, { searchParams : query }).json();
    commit(Types.RECEIVE_BROCHURES, response);
  },
  async getPropertyBrochures({ commit }, { tenantId, propertyId }) {

    const response = await HestiaApi.Property.property.brochures(tenantId, propertyId).json();
    commit(Types.RECEIVE_PROPERTY_BROCHURES, response);
  },
  async getBrochure({ commit }, { tenantId, brochureId }) {

    const response = await HestiaApi.Property.brochure.get(tenantId, brochureId).json();
    commit(Types.RECEIVE_PROPERTY_BROCHURE, response);
  },
  async addBrochure({ commit }, { tenantId, brochure }) {

    const response = await HestiaApi.Property.brochure.create(tenantId, brochure).json();
    commit(Types.ADD_BROCHURE, response);
    return response;
  },
  async removeBrochure({ commit }, { tenantId, id }) {

    await HestiaApi.Property.brochure.delete(tenantId, id).json();
    commit(Types.REMOVE_BROCHURE, id);
  },
  async editPropertyBrochure({ commit }, { tenantId, propertyId, brochure }) {

    const response = await HestiaApi.Property.brochure.update(tenantId, propertyId, brochure).json();
    commit(Types.EDIT_PROPERTY_BROCHURE, response);
  },
  // PUBLICATION
  async getPublications({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Property.publication.list(tenantId, { searchParams : query }).json();
    commit(Types.RECEIVE_PUBLICATIONS, response);
  },
  async getPropertyPublications({ commit }, { tenantId, propertyId }) {

    const response = await HestiaApi.Property.publication.listByProperty(tenantId, propertyId).json();
    commit(Types.RECEIVE_PROPERTY_PUBLICATIONS, response);
  },
  async getPublication({ commit }, { tenantId, publicationId }) {

    const response = await HestiaApi.Property.publication.get(tenantId, publicationId).json();
    commit(Types.RECEIVE_PROPERTY_PUBLICATION, response);
  },
  async addPropertyPublication({ commit }, { tenantId, propertyId, publication }) {

    const response = await HestiaApi.Property.publication.add(tenantId, propertyId, publication).json();
    commit(Types.ADD_PROPERTY_PUBLICATION, response);
    return response;
  },
  async removePropertyPublication({ commit }, { tenantId, id }) {

    await HestiaApi.Property.publication.delete(tenantId, id);
    commit(Types.REMOVE_PROPERTY_PUBLICATION, id);
  },
  async editPropertyPublication({ commit }, { tenantId, propertyId, publication }) {

    const response = await HestiaApi.Property.publication.update(tenantId, propertyId, publication).json();
    commit(Types.EDIT_PROPERTY_PUBLICATION, response);
  },
  async changePropertyHistoryFrom({ commit }, { tenantId, propertyId, id }) {

    const response = await HestiaApi.Property.history.from(tenantId, propertyId, id).json();
    commit(Types.CHANGE_PROPERTY_HISTORY_FROM, response);
  },

  // TYPES
  async getPropertyDocumentTypes({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Property.documentType.list(tenantId, { searchParams : query }).json();
    commit(Types.RECEIVE_PROPERTY_DOCUMENT_TYPES, response);
  },
  async addPropertyDocumentType({ commit }, { tenantId, documentType }) {

    const response = await HestiaApi.Property.documentType.create(tenantId, documentType).json();
    commit(Types.ADD_PROPERTY_DOCUMENT_TYPE, response);
  },
  async editPropertyDocumentType({ commit }, { tenantId, id, documentType }) {

    const response = await HestiaApi.Property.documentType.update(tenantId, id, documentType).json();
    commit(Types.EDIT_PROPERTY_DOCUMENT_TYPE, response);
  },
  async deletePropertyDocumentType({ commit }, { tenantId, id }) {

    const response = await HestiaApi.Property.documentType.delete(tenantId, id);
    commit(Types.REMOVE_PROPERTY_DOCUMENT_TYPE, response);
  },
  selectPropertyMediaTab({ commit }, { tab }) {

    commit(Types.SELECT_PROPERTY_MEDIA_TAB, { tab });
  }
};

// mutations
const mutations = {
  [Types.RECEIVE_PROPERTIES](state, properties) {

    state.properties = properties;
  },
  [Types.RECEIVE_PROPERTIES_MAP](state, properties) {

    state.properties_map = properties;
  },
  [Types.RECEIVE_PROPERTY_APPOINTMENTS](state, property_appointments) {

    state.currentPropertyAppointments = property_appointments;
  },
  [Types.RECEIVE_PROPERTY_ISSUES](state, property_issues) {

    state.currentPropertyIssues = property_issues;
  },
  [Types.RECEIVE_PROPERTY_OVERVIEW](state, property_overview) {

    state.currentPropertyOverview = property_overview;
  },
  [Types.RECEIVE_PROPERTY_FEEDS](state, property_feeds) {

    state.currentPropertyFeeds = property_feeds;
  },
  [Types.BLACKLIST_PROPERTY_FEEDS](state, property_feeds) {

    const index = state.currentPropertyFeeds.results.findIndex((prop) => prop.feedId === property_feeds.feedId);
    state.currentPropertyFeeds.results.splice(index, 1, property_feeds);
  },
  [Types.RECEIVE_PROPERTY_MATCHES](state, property_matches) {

    state.currentPropertyMatches = property_matches;
  },
  [Types.CURRENT_CLIENT_UPDATED_FROM](state, updated_from) {

    state.currentClientUpdateFrom = updated_from;
  },
  [Types.RECEIVE_PROPERTY_REQUESTS](state, property_requests) {

    state.currentPropertyRequests = property_requests;
  },
  [Types.EDIT_PROPERTY](state, property) {

    state.currentProperty = {
      ...property,
      mandates       : state.currentProperty.mandates,
      threads_issues : state.currentProperty.threads_issues
    };

    const index = state.properties.results.findIndex((prop) => prop.id === property.id);
    state.properties.results.splice(index, 1, property);

    state.currentPropertyCounts = {
      ...state.currentPropertyCounts,
      'mandates'  : 'mandates' in property ? property.mandates.length : 0,
      'images'    : 'images' in property ? property.images.length : 0,
      'documents' : 'documents' in property ? property.documents.length : 0
    };
  },
  [Types.REMOVE_PROPERTY](state, propertyId) {

    const index = state.properties.results.findIndex((property) => property.id === propertyId);
    if (index > -1) {
      state.properties.results.splice(index, 1);
    }
  },
  [Types.SELECT_PROPERTY](state, property) {

    state.currentProperty = {
      ...property
    };
  },
  [Types.ADD_PROPERTY_MANDATE](state, mandate) {

    state.currentProperty.mandates.push(mandate);
  },
  [Types.REMOVE_PROPERTY_MANDATE](state, mandateId) {

    const index = state.currentProperty.mandates.findIndex((mandate) => mandate.id === mandateId);
    if (index > -1) {
      state.currentProperty.mandates.splice(index, 1);
    }
  },
  [Types.INIT_PROPERTY_COUNTS](state) {

    state.currentPropertyCounts = {
      'matches'      : null,
      'mandates'     : null,
      'feeds'        : null,
      'appointments' : null,
      'documents'    : null,
      'images'       : null,
      'medias'       : null,
      'issues'       : null,
      'offers'       : null,
      'requests'     : null,
      'publications' : null
    };
  },
  [Types.UPDATE_PROPERTY_COUNTS](state, { property, counts }) {

    state.currentPropertyCounts = {
      'matches'      : !isNaN(counts.matches) ? counts.matches : state.currentPropertyCounts.matches,
      'mandates'     : 'mandates' in property ? property.mandates.length : null,
      'feeds'        : !isNaN(counts.feeds) ? counts.feeds : state.currentPropertyCounts.feeds,
      'appointments' : !isNaN(counts.appointments) ? counts.appointments : state.currentPropertyCounts.appointments,
      'documents'    : 'documents' in property ? property.documents.length : null,
      'images'       : 'images' in property ? property.images.length : null,
      'medias'       : 'media' in property ? property.media.length : null,
      'issues'       : !isNaN(counts.issues) ? counts.issues : state.currentPropertyCounts.issues,
      'offers'       : !isNaN(counts.offers) ? counts.offers : state.currentPropertyCounts.offers,
      'requests'     : !isNaN(counts.requests) ? counts.requests : state.currentPropertyCounts.requests,
      'publications' : 'publications' in property ? property.publications.length : null,
      'brochures'    : 'brochures' in property ? property.brochures.length : null
    };
  },
  [Types.PROPERTY_ADD_IMAGE](state, { propertyId, ...image }) {

    const index = state.properties.results.findIndex((m) => m.id === propertyId);

    state.currentProperty.images.push(image);

    if (index !== -1) {
      state.properties.results[index].images.push(image);
      state.properties.results.splice(index, 1, state.properties.results[index]);
    }

    // Update counts
    state.currentPropertyCounts['images']++;
  },
  [Types.EDIT_PROPERTY_IMAGE](state, images) {

    state.currentProperty.images = images;
  },
  [Types.REMOVE_PROPERTY_IMAGE](state, imageId) {

    const index = state.currentProperty.images.findIndex((a) => a.id === imageId);

    state.currentProperty.images.splice(index, 1);
  },
  [Types.RECEIVE_PROPERTY_FEATURES](state, features) {

    state.property_features = features;
  },
  [Types.ADD_PROPERTY_FEATURE](state, feature) {

    const features = state.property_features.results;
    if (features.length === 0 || features[features.length - 1].order + 1 === feature.order) {

      state.property_features.results.push(feature);
    }
  },
  [Types.EDIT_PROPERTY_FEATURE](state, feature) {

    const index = state.property_features.results.findIndex((feat) => feat.id === feature.id);
    state.property_features.results.splice(index, 1, feature);
  },
  [Types.EDIT_PROPERTY_FEATURE_ORDER](state, { feature, newOrder, oldOrder }) {

    if (newOrder > oldOrder) {

      state.property_features.results.reduce((acc, t) => {

        if (t.order > oldOrder && t.order <= newOrder) {
          t.order = t.order - 1;
        }

        acc.push(t);

        return acc;
      }, []);

    } else {

      state.property_features.results.reduce((acc, t) => {

        if (t.order < oldOrder && t.order >= newOrder) {
          t.order = t.order + 1;
        }

        acc.push(t);

        return acc;
      }, []);
    }

    const index = state.property_features.results.findIndex((t) => t.id === feature.id);

    state.property_features.results.splice(index, 1, feature);
  },
  [Types.REMOVE_PROPERTY_FEATURE](state, featureId) {

    const index = state.property_features.results.findIndex((feat) => feat.id === featureId);
    state.property_features.results.splice(index, 1);
  },
  [Types.RECEIVE_PROPERTY_CATEGORIES](state, categories) {

    state.property_categories = categories;
  },
  [Types.ADD_PROPERTY_CATEGORY](state, category) {

    state.property_categories.results.push(category);
  },
  [Types.EDIT_PROPERTY_CATEGORY](state, category) {

    const index = state.property_categories.results.findIndex((cat) => cat.id === category.id);
    state.property_categories.results.splice(index, 1, category);
  },
  [Types.EDIT_PROPERTY_CATEGORY_ORDER](state, { category, newOrder, oldOrder }) {

    if (newOrder > oldOrder) {

      state.property_categories.results.reduce((acc, t) => {

        if (t.order > oldOrder && t.order <= newOrder) {
          t.order = t.order - 1;
        }

        acc.push(t);

        return acc;
      }, []);

    } else {

      state.property_categories.results.reduce((acc, t) => {

        if (t.order < oldOrder && t.order >= newOrder) {
          t.order = t.order + 1;
        }

        acc.push(t);

        return acc;
      }, []);
    }

    const index = state.property_categories.results.findIndex((t) => t.id === category.id);

    state.property_categories.results.splice(index, 1, category);
  },
  [Types.REMOVE_PROPERTY_CATEGORY](state, categoryId) {

    const index = state.property_categories.results.findIndex((cat) => cat.id === categoryId);
    state.property_categories.results.splice(index, 1);
  },

  // Properties - type
  [Types.RECEIVE_PROPERTY_TYPES](state, { types, arrayName = 'property_types' }) {

    state[arrayName] = types;
  },

  // Primary / Secondary
  [Types.ADD_PROPERTY_TYPE](state, { type, arrayName }) {

    state[arrayName].push(type);
  },
  [Types.EDIT_PROPERTY_TYPE](state, { type, arrayName }) {

    const index = state[arrayName].findIndex((t) => t.id === type.id);
    state[arrayName].splice(index, 1, type);
  },
  [Types.REMOVE_PROPERTY_TYPE](state, { typeId, arrayName }) {

    const index = state[arrayName].findIndex((t) => t.id === typeId);
    state[arrayName].splice(index, 1);
  },
  [Types.EDIT_PROPERTY_TYPE_ORDER](state, { type, newOrder, oldOrder, arrayName }) {

    if (newOrder > oldOrder) {

      state[arrayName].reduce((acc, t) => {

        if (t.order > oldOrder && t.order <= newOrder) {
          t.order = t.order - 1;
        }

        acc.push(t);

        return acc;
      }, []);

    } else {

      state[arrayName].reduce((acc, t) => {

        if (t.order < oldOrder && t.order >= newOrder) {
          t.order = t.order + 1;
        }

        acc.push(t);

        return acc;
      }, []);
    }

    const index = state[arrayName].findIndex((t) => t.id === type.id);

    state[arrayName].splice(index, 1, type);
  },

  // ADD
  [Types.ADD_PROPERTY_ISSUE](state, issue) {

    // Manually Add
    state.currentPropertyIssues.results.unshift(issue);
    state.currentPropertyIssues.pagination.total++;

    if (state.currentPropertyIssues.pagination.total > state.currentPropertyIssues.pagination.perPage) {
      state.currentPropertyIssues.results.pop();
    }

    // Update counts
    state.currentPropertyCounts['issues']++;
  },
  [Types.ADD_PROPERTY_MEDIUM](state, property) {

    state.currentProperty.media = property.media;
    state.currentPropertyCounts['medias']++;
  },
  [Types.EDIT_PROPERTY_MEDIUM](state, property) {

    state.currentProperty.media = property.media;
  },
  [Types.REMOVE_PROPERTY_MEDIUM](state, mediumId) {

    const index = state.currentProperty.media.findIndex((a) => a.id === mediumId);
    state.currentPropertyCounts['medias']--;
    state.currentProperty.media.splice(index, 1);
  },

  [Types.ADD_PROPERTY_APPOINTMENT](state) {

    // Update counts
    state.currentPropertyCounts['appointments']++;
  },
  [Types.ADD_PROPERTY_OFFER](state) {

    // Update counts
    state.currentPropertyCounts['offers']++;
  },
  [Types.RECOUNT_PROPERTY_FEED](state, feed) {

    // Update counts
    state.currentPropertyCounts.feeds = feed.count;
  },
  // ACL
  [Types.REMOVE_PROPERTY_COLLABORATOR](state, collaborator) {

    const index = state.currentProperty.collaborators.findIndex((c) => c.id === collaborator.id);
    state.currentProperty.collaborators.splice(index, 1);
  },
  [Types.REMOVE_PROPERTY_COOWNER](state, coowner) {

    const index = state.currentProperty.coOwners.findIndex((c) => c.id === coowner.id);
    state.currentProperty.coOwners.splice(index, 1);
  },
  [Types.RECEIVE_PROPERTY_OFFERS](state, offers) {

    state.propertyOffers = offers;
  },
  [Types.ADD_OFFER](state, offer) {

    state.propertyOffers.results.push(offer);
  },
  [Types.EDIT_OFFER](state, offer) {

    const index = state.propertyOffers.results.findIndex((of) => of.id === offer.id);
    state.propertyOffers.results.splice(index, 1, offer);
  },
  [Types.RECEIVE_PROPERTY_SOURCES](state, sources) {

    state.properties_sources = sources;
  },
  [Types.ADD_PROPERTY_SOURCE](state, source) {

    state.properties_sources.push(source);
  },
  [Types.REMOVE_PROPERTY_SOURCE](state, source) {

    const index = state.properties_sources.findIndex((s) => s.id === source.id);
    state.properties_sources.splice(index, 1);
  },
  [Types.EDIT_PROPERTY_SOURCE](state, source) {

    const index = state.properties_sources.findIndex((s) => s.id === source.id);
    state.properties_sources.splice(index, 1, source);
  },
  // BROCHURE
  [Types.RECEIVE_BROCHURES](state, brochures) {

    state.properties_brochures = brochures;
  },
  [Types.RECEIVE_PROPERTY_BROCHURES](state, brochures) {

    state.currentPropertyBrochures = brochures;
  },
  [Types.ADD_BROCHURE](state, brochure) {

    state.properties_brochures.push(brochure);
  },
  [Types.REMOVE_BROCHURE](state, id) {

    const index = state.properties_brochures.findIndex((b) => b.id === id);
    state.properties_brochures.splice(index, 1);
  },
  [Types.EDIT_PROPERTY_BROCHURE](state, brochure) {

    const index = state.properties_brochures.findIndex((b) => b.id === brochure.id);
    state.properties_brochures.splice(index, 1, brochure);
  },

  // PUBLICATION
  [Types.RECEIVE_PUBLICATIONS](state, publications) {

    state.properties_publications = publications;
  },
  [Types.RECEIVE_PROPERTY_PUBLICATIONS](state, publications) {

    state.currentPropertyPublications = publications;
  },
  [Types.ADD_PROPERTY_PUBLICATION](state, publication) {

    state.currentPropertyPublications.push(publication);
    state.currentPropertyCounts['publications']++;
  },
  [Types.REMOVE_PROPERTY_PUBLICATION](state, id) {

    const index = state.currentPropertyPublications.findIndex((p) => p.id === id);
    state.currentPropertyCounts['publications']--;
    state.currentPropertyPublications.splice(index, 1);
  },
  [Types.EDIT_PROPERTY_PUBLICATION](state, publication) {

    const index = state.currentPropertyPublications.findIndex((p) => p.id === publication.id);
    state.currentPropertyPublications.splice(index, 1, publication);
  },
  [Types.CHANGE_PROPERTY_HISTORY_FROM](state, history) {

    state.currentProperty.history = history;
  },

  // TYPES
  [Types.RECEIVE_PROPERTY_DOCUMENT_TYPES](state, documentTypes) {

    state.propertyDocumentTypes = documentTypes;
  },
  [Types.ADD_PROPERTY_DOCUMENT_TYPE](state, documentType) {

    state.propertyDocumentTypes.results.unshift(documentType);
    if (state.propertyDocumentTypes.results.length === state.propertyDocumentTypes.pagination.perPage) {
      state.propertyDocumentTypes.results.pop();
    }
  },
  [Types.EDIT_PROPERTY_DOCUMENT_TYPE](state, documentType) {

    const index = state.propertyDocumentTypes.results.findIndex((propertyDocumentType) => propertyDocumentType.id === documentType[0].id);
    state.propertyDocumentTypes.results.splice(index, 1, documentType[0]);
  },
  [Types.REMOVE_PROPERTY_DOCUMENT_TYPE](state, id) {

    const index = state.propertyDocumentTypes.results.findIndex((propertyDocumentType) => propertyDocumentType.id === id);
    state.propertyDocumentTypes.results.splice(index, 1);
  },

  // DOCUMENTS
  [Types.ADD_PROPERTY_DOCUMENT](state, document) {

    state.currentProperty.documents.push(document);
    state.currentPropertyCounts['documents']++;
  },
  [Types.EDIT_PROPERTY_DOCUMENT](state, document) {

    const index = state.currentProperty.documents.findIndex((a) => a.id === document.id);
    state.currentProperty.documents.splice(index, 1, document);
  },
  [Types.DELETE_PROPERTY_DOCUMENT](state, { documentId, isSuperAdmin }) {

    const index = state.currentProperty.documents.findIndex((document) => document.id === documentId);

    if (isSuperAdmin) {

      state.currentProperty.documents[index].deleted = true;
      return;
    }

    state.currentProperty.documents.splice(index, 1);
    state.currentPropertyCounts['documents']--;
  },
  [Types.UNDELETE_PROPERTY_DOCUMENT](state, documentId) {

    const index = state.currentProperty.documents.findIndex((document) => document.id === documentId);
    state.currentProperty.documents[index].deleted = false;
  },
  [Types.SELECT_PROPERTY_MEDIA_TAB](state, tab) {

    state.propertyMediaTab = tab;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
